/***
**Module Name: contact us 
 **File Name :  contactus.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains contact us table details.
 ***/
 import React, { useState, useEffect, useContext } from "react";
 import Footer from "../../../components/dashboard/footer";
 import Header from "../../../components/dashboard/header";
 import Sidebar from "../../../components/dashboard/sidebar";
 import tmdbApi from "../../../api/tmdbApi";
 import { useHistory, Link } from "react-router-dom";
 import SweetAlert from 'react-bootstrap-sweetalert';
 import axios from 'axios';
 import moment from "moment";
 import ViewContactList from "../contactus/viewContactus";
 import Loader from "../../../components/loader";
 import { contentContext } from "../../../context/contentContext";
 import TableLoader from "../../../components/tableLoader";
 import SessionPopup from "../../SessionPopup"
 import DataTable from 'react-data-table-component';
import ViewVideoRequest from "./viewVideoRequest";
import Tooltip from '@mui/material/Tooltip'
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

 let { appname, lambda } = window.app;
 
 
 const Videorequest = () => {
     const history = useHistory();
     // const [toggle, setToggle] = useState(false);
     const [content, setContent] = useState("");
     const [perpage, setPerpage] = useState(10);
     const [searchRequesterName, setSearchRequesterName] = useState("");
 
     const [rangeError, setRangeError] = useState(""); 
     const getFormattedDate = (date) => {
        // Convert to local time and format the date as YYYY-MM-DD
        return moment(date).local().format('YYYY-MM-DD');
      };
      const currentDate = new Date();
      let sixMonthsAgo = new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, currentDate.getDate());
      const difference = currentDate.getTime() - sixMonthsAgo.getTime();
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    //   const thirtyDaysAgo = new Date();
    //   thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 1);
      const formattedFromDate = getFormattedDate(threeMonthsAgo);
      const formattedToDate = getFormattedDate(new Date());
    
      const [fromdate, setFromDate] = useState(formattedFromDate);
      const [todate, setToDate] = useState(formattedToDate);
   
    //  const [fromdate, setFromDate] = useState(() => {
    //      // Set default value for fromdate (one month ago)
    //      const oneMonthAgo = new Date();
    //      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    //      return oneMonthAgo.toISOString().split('T')[0];
    //    });
       
    //    const [todate, setToDate] = useState(() => {
    //      // Set default value for todate (today)
    //      return new Date().toISOString().split('T')[0];
    //    });
       

    //  const [data, setData] = useState([]);
    const [tableErrMsg, setTableErrMsg] = useState(false);



     const [sortDirection, setSortDirection] = useState('asc');
    const [arrowdir, setArrowDir] = useState('down');
    const [num, setNum]= useState();
 
     //const [search, setSearch] = useState("");
     const [viewContact, setViewContact] = useState({});
     const [click, setClick] = useState(false);
     const [TitleSearch, setTitleSearch] = useState("");

    //  const [isLoading, setIsLoading] = useState(false);
     const [showSessionPopupup, setShowSessionPopupup] = useState(false);
     const { isLoading, setIsLoading,sortTableAlpha , arrow,sortTableByDate,userData , data, setData,rowsPerPage, setRowsPerPage,currentPageNew, setCurrentPage,route, setRoute,usePrevious,setActiveMenuObj,setActiveMenuId,  GetTimeActivity} = useContext(contentContext)
 

     const validateObj = userData && userData.permissions && userData.permissions.length > 0 && userData.permissions.filter(eachItem=>eachItem.menu == "Reports")
     const subValDashboard = validateObj && validateObj[0] && validateObj[0].submenus && validateObj[0].submenus[2] && validateObj[0].submenus[2].dashboard
    //  console.log('subValDashboard obj',subValDashboard)


         

     

    const prevRoute = usePrevious(route)
    useEffect(() => {
        if(prevRoute != undefined && prevRoute!=route){
            setCurrentPage(1)
            setRowsPerPage(15)
        }
    }, [prevRoute]);
   //  console.log('prevRoute--->',prevRoute)
   //  console.log('currentRoute--->',route)
   const keyForRerender = `${rowsPerPage}_${data?.length}`;
    const columns = [

        {
            name: 'Title',
            selector: row => row?.contentData?.title ?? "",
            sortable: true,
        },
        {
            name: 'Requester name',
            selector: row => row?.requestername ?? "",
            sortable: true,
        },
  
         { 
            name: 'Video Uploaded',
            cell: (props) =>   
            <div className="availability"><span className="material-symbols-outlined">{props?.emailSent == true ?'video_library':'videocam_off'}</span>{props?.emailSent == true ? 'YES':'NO'}</div>
            ,
          
            sortable: false,
        },
        {
           name: 'Requested Date',
        //    selector: row => moment(row.created).utc().format("DD-MMM-YYYY HH:mm"),
        selector: row => row?.created,
        cell: (props) =>   props.created != undefined ?  new Date(props.created).toLocaleDateString('en-IN', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
      }) :'',
      sortable: true,
        //   sortFunction: (a, b) => moment(a.created).diff(b.created),
       },
       {
        name: 'Video Uploaded Date',
     //    selector: row => moment(row.created).utc().format("DD-MMM-YYYY HH:mm"),
        

       selector: row => row?.videoAvailbleOn,
       cell: (props) =>   props.videoAvailbleOn != undefined ?  new Date(props.videoAvailbleOn).toLocaleDateString('en-IN', {
         day: 'numeric',
         month: 'short',
         year: 'numeric',
         hour: 'numeric',
         minute: 'numeric',
     }) :'',
     sortable: true,
    //    sortFunction: (a, b) => moment(a.videoAvailbleOn).diff(b.videoAvailbleOn),
    },
        {
            name: <>{subValDashboard && subValDashboard.view && subValDashboard.view.display === true && 'Actions'}</>,
            cell: (props) => 
           //   {
            subValDashboard && subValDashboard.view && subValDashboard.view.display === true &&
           <div className="d-flex">
           <a className={`${ subValDashboard && subValDashboard.view && subValDashboard.view.enable === false ? 'pe-none':''} text-success action-button`} onClick={(e)=>handleContactView(e,props._id)}><i className="mdi mdi-eye font-size-18"></i></a>

       </div>
           //  }
            ,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
   
    const handlePageChange = (page) => {
        setCurrentPage(page);
      };
    
      const handlePerRowsChange = (newPerPage) => {
        setRowsPerPage(newPerPage);
      };
    

   
 
     useEffect(() => {
         if (!localStorage.getItem("token")) {
             history.push("/");
         }
         setRoute("videorequests")
         setActiveMenuId(300054)
         setActiveMenuObj({
             "Client Management": false,
             "Contact Management": false,
             "Reports": true
         })
            getVideoRequest();
            userActivity();
     }, []);
 
     const userActivity = () => {
        let path = window.location.pathname.split("/");
        const pageName = path[path.length - 1];
        var presentTime = moment();
        let payload;

        payload = {
            "userid": localStorage.getItem("userId"),
            "pagename": pageName,
            "pageurl": window.location.href,
            "starttime": presentTime,
            "useragent": JSON.parse(localStorage.getItem("loc"))

        };


   const previousId = localStorage.getItem("previousid");
const urlLink = lambda + '/useractivity?appname=' + appname + (previousId ? "&previousid=" + previousId : "");


        axios({
            method: 'POST',
            url: urlLink,
            data: payload
        })
            .then(function (response) {
                if (response?.data?.statusCode === 200) {
                    localStorage.setItem("previousid", response?.data?.result)
                }
            });
    }


     const customNoRecords = () => {
        return(
            
            <div className="empty-state-body empty-record"  >
                <div className="empty-state__message">
                    <span className="material-icons">summarize</span>
                    <p className="form-check font-size-16">No titles were found with the searched keyword</p>
                </div> </div>
        )
    }
    const validate = () => {
        GetTimeActivity()   
        let flag = true
        if (fromdate && todate) {
            if (new Date(fromdate) > new Date(todate)) {
                setRangeError("To date cannot be earlier than From date")
                flag = false;
            } else {
                const fromDateObj = new Date(fromdate);
                const toDateObj = new Date(todate);
                const dateDifference = Math.abs(toDateObj - fromDateObj) / (1000 * 60 * 60 * 24);
            
                if (dateDifference > days) {
                    setRangeError('Date range should be maximum 6 months');
                    flag = false;
                } else {
                    setRangeError("");
                  // Perform your search logic here
                }
                // setRangeError("")
            }
        }
        if(fromdate === ""){
            setRangeError("Please select From Date")
            flag = false;
        }else if(todate === ""){
            setRangeError("Please select To Date")
            flag = false;
        } 
     
        return flag
    }

    const handleRange = (e)=>{
        GetTimeActivity()   
        setRangeError(false)
        if(e.target.name==="requestedfromdate"){
          setFromDate(e.target.value)
        }else{
          setToDate(e.target.value)
        }
    }
     

     const getVideoRequest = () => {
        GetTimeActivity()   
        const valid = validate()
        if(valid){
        setCurrentPage(1);
       
            setIsLoading(true)
            const token = localStorage.getItem("token")
         const linkUrl = `${lambda}/requestMetaDataReports?appname=${appname}&token=${token}&userid=${localStorage.getItem("userId")}` +  (TitleSearch ? `&search=${TitleSearch}` : '') + (searchRequesterName ? `&requestername=${searchRequesterName}` :'');
         let payload ={};

          if(fromdate){
            payload.requestedfromdate =fromdate
          }
          if(todate){
            payload.requestedtodate =todate
          }
        
       
             axios({
                 method: 'POST',
                 url: linkUrl,
                 data:payload
             })
                 .then(function (response) {
                     if (response?.data?.result == "Invalid token or Expired") {
                        setShowSessionPopupup(true)
                    } else {
                         console.log("response", response);
                         setData(response?.data?.result?.data);
                         global.exportExcelData = response?.data?.result?.data
                      

                         setContent(response?.data?.result);
                         setIsLoading(false)
                     }
                 }).catch((err) => {
                    console.log('error', err);
                    setTableErrMsg(true)
                    setIsLoading(false);
                });
                }
     }
 
     const handleChange = (e) => {
        
         if(e.target.name === "searchTitle")
         {setTitleSearch(e.target.value)}
        else{
            console.log('eeee',e.target.value)
         setSearchRequesterName(e.target.value)
        }
     }
 
     const handleKeypress = (e) => {
         //it triggers by pressing the enter key
         GetTimeActivity()   
       if ((e.key === "Enter")) {
         setTimeout(function () { 
         getVideoRequest();
     }, 1000);
       }
     };

     const handleContactView = (e, id) => {
        GetTimeActivity()   
        for (let key in data) {
          console.log("data",data);
          if (data.hasOwnProperty(key) && data[key]._id === id) {

            setViewContact(data[key]);
            setClick(true);
            
          }
        }
      };

     useEffect(() => {
        // const oneMonthAgo = new Date();
        // oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        // const formattedFromDate = oneMonthAgo.toISOString().split('T')[0];
        // // setFromDate(formattedFromDate);

        // // Set default value for todate (today)
        // const formattedToDate = new Date().toISOString().split('T')[0];
        // // setToDate(formattedToDate);
        if (fromdate === formattedFromDate && todate === formattedToDate && TitleSearch === "" &&  searchRequesterName === "") {
             getVideoRequest();
         }
     }, [TitleSearch, todate, fromdate, searchRequesterName])
      const clearSearch = () => {
        GetTimeActivity()   
        setTitleSearch("");
        setSearchRequesterName("");
        // const oneMonthAgo = new Date();
        // oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        // const formattedFromDate = oneMonthAgo.toISOString().split('T')[0];
        setFromDate(formattedFromDate);

        // Set default value for todate (today)
        // const formattedToDate = new Date().toISOString().split('T')[0];
        setToDate(formattedToDate);
        setRangeError(false)
     }
     const handleExport = () => {
       
              // let k = defaultData || exportExcelData
          const modifiedArray = global.exportExcelData.map((each,i) => ({
                "S.NO":i+1,
                "TITLE": each?.contentData?.title,
                "REQUESTER NAME": each?.requestername,
                "VIDEO UPLOADED": each?.emailSent == true ? 'YES':'NO',
                "REQUESTED DATE":  each?.created ? new Date(each?.created).toLocaleDateString('en-IN', {
                    day: 'numeric',
                    month: 'short', 
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                   
                }) :"",
                "VIDEO UPLOADED DATE":   each?.videoAvailbleOn ? new Date(each?.videoAvailbleOn).toLocaleDateString('en-IN', {
                    day: 'numeric',
                    month: 'short', 
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                   
                }) :"",
              }));
            // console.log('data2 after',data2)
    
            exportToExcel(modifiedArray, "data");
    
        }
        function exportToExcel(jsonData, fileName) {
            delete jsonData['_id'];
            const worksheet = XLSX.utils.json_to_sheet(jsonData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
            //FileSaver.saveAs(data, `${fileName}.xlsx`);
            FileSaver.saveAs(data, `VideoRequestsData.xlsx`);
        }
        const customSort = (rows, selector, direction) => {
            const sortedData = rows.slice().sort((rowA, rowB) => {
              // use the selector function to resolve your field names by passing the sort comparators
              // const aField = selector(rowA);
        const aField = String(selector(rowA)).toLowerCase();

              //  const bField = selector(rowB);
          const bField = String(selector(rowB)).toLowerCase();
          
              let comparison = 0;
          
              if (aField > bField) {
                comparison = 1;
              } else if (aField < bField) {
                comparison = -1;
              }
          
              return direction === 'desc' ? comparison * -1 : comparison;
            });
          
            // Log the sorted data to the console
            // setSortedRecomData(sortedData)
            global.exportExcelData = sortedData
            // console.log('Sorted Data------>:', sortedData);
          
            return sortedData;
          }; 
     return (
         <>
 {showSessionPopupup && <SessionPopup />}
             <div id="layout-wrapper">
                 <Header />
                 <Sidebar />
                { click ?  (<ViewVideoRequest data={viewContact} click={click} setClick={setClick}  />) :
                ( <div className="main-content user-management clients clients-search contact video-request">
 
                     <div className="page-content">
                         <div className="container-fluid">
 
 
 
                             <div className="row mb-4 breadcrumb">
                                 <div className="col-lg-12">
                                     <div className="d-flex align-items-center">
                                         <div className="flex-grow-1">
                                             <h4 className="mb-2 card-title">Video Requests</h4>
                                         </div>
                                     </div>
                                 </div>
                             </div>
 
                             <div className="row table-data">
                                 <div className="col-12">
                                     <div className="card">
                                         <div className="card-body">
                                             <div className="row mb-2">
                                             <div className="col-sm-4 button-block">
                                                <div className="dataTables_length" id="datatable_length">
                                                                         <button className={`fill_btn ${data.length <= 0 ?'disable pe-none':''}`} onClick={handleExport}><span className="material-icons">north_east</span>Export</button>
                                                                     </div>
                                                </div>
                                                 <div className="col-sm-8">
 
                                                     <div className="search-box mb-2 d-inline-block">
                                                         <div className="position-relative">
                                                             <input type="text" name="searchTitle" className="form-control" placeholder="Search by title" value={TitleSearch} onChange={(e) => handleChange(e)} onKeyPress={handleKeypress} />
                                                             <input type="text" name="searchRName" className="form-control" placeholder="Search by requester name" value={searchRequesterName} onChange={(e) => handleChange(e)}  />

                                                             <div className="input-daterange input-group" id="datepicker6" data-date-format="dd M, yyyy" data-date-autoclose="true" data-provide="datepicker" data-date-container='#datepicker6'>

                                                             <Tooltip title="From Date" placement="top">

                                   <input type="date" className="form-control date-input" name="requestedfromdate" placeholder="Search from" value={fromdate} onChange={(e) => handleRange(e)} max={new Date().toISOString().split('T')[0]} /> 
                                    </Tooltip>

                                   <Tooltip title="To Date" placement="top">

                                   <input type="date" className="form-control date-input" name="requestedtodate" placeholder="Search To" value={todate} onChange={(e) => handleRange(e)} max={new Date().toISOString().split('T')[0]}/>
                                     </Tooltip>
                                 </div>

                                                         
                                                             <button className="fill_btn" onClick={getVideoRequest}><span className="material-icons search-icon">search</span></button>
                                                         </div>
                                                         <div className="dataTables_length" id="datatable_length">
                                                                <button className="fill_btn" onClick={clearSearch}><span className="material-icons-outlined">sync</span>Reset</button>
                                                            </div>
 
                                                      
                                                     </div>
                                                     <div className="text-sm-end">
                                                     {rangeError != "" ?  <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{rangeError}</span> :null} 
                                                     </div>
                                                 </div>
                                             </div>
 
                                               < DataTable  key={keyForRerender}
                                                 // title=""
                                                 columns={columns}
                                                 // className="table align-middle table-nowrap table-check"
                                                 keyField='_id'
                                                 data={data}
                                                 direction="auto"
                                                 highlightOnHover
                                                 fixedHeaderScrollHeight="300px"
                                                 pagination
                                                 responsive
                                                 persistTableHead
                                                 // selectableRowsVisibleOnly
                                                 striped
                                                 // selectableRowsHighlight
                                                 // selectableRows
                                                 subHeaderAlign="right"
                                                 defaultSortField="name"
                                                 subHeaderWra
                                                 noDataComponent={customNoRecords()}
                                                 paginationTotalRows={data.length}
                                                 onChangeRowsPerPage={handlePerRowsChange}
                                                 onChangePage={handlePageChange}
                                                 paginationPerPage={rowsPerPage}
                                                 paginationDefaultPage={currentPageNew}
                                                paginationRowsPerPageOptions={[15,25,50,75,100]}
                                                sortFunction={customSort}
                                                 paginationComponentOptions={{
                                                     rowsPerPageText: 'Per page:',
                                                     rangeSeparatorText: 'of',
                                                     noRowsPerPage: false,
                                                     selectAllRowsItem: false,
                                                     selectAllRowsItemText: 'All',
                                                   }}
                                           
                                                 progressPending={isLoading}
                                                 progressComponent={<TableLoader />}
                                                   />
 
                                         </div>
                                     </div>
                                 </div>
                             </div>
 
 
                         </div>
                     </div>
                     <SweetAlert show={tableErrMsg}
                         custom
                         confirmBtnText="ok"
                         confirmBtnBsStyle="primary"
                         title={"Something went wrong. Please reload again."}
                         onConfirm={e => setTableErrMsg(false)}
                     >
                     </SweetAlert>
 
 
                     <Footer />
                   
                 </div>)
 }
             </div>
         </>
     );
 };
 
 export default Videorequest;
 