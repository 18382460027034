/***
**Module Name: client
 **File Name :  client.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains clients table details.
 ***/
import React, { useState, useEffect,useRef, useContext } from "react";


import Footer from "../../components/dashboard/footer";
import Header from "../../components/dashboard/header";
import Sidebar from "../../components/dashboard/sidebar";
import tmdbApi from "../../api/tmdbApi";
import { useHistory, Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import CurrencyInput from 'react-currency-input-field';

import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import SessionPopup from "../SessionPopup"
import moment from "moment";
import Select from 'react-select';
import { v4 as uuid } from 'uuid';
import * as Config from "../../constants/Config";

import TableLoader from "../../components/tableLoader";
import { contentContext } from "../../context/contentContext";
import Loader from "../../components/loader"
import {getDealFiles } from '../../utils/reducer'
import FileViewer from "../../components/docViewer";
import Multiselect from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";
let { lambda, appname } = window.app;



const EditDeals = () => {
    const history = useHistory();
    let { id } = useParams();
    const ref = useRef();
    const [image, setImg] = useState('');
    const [allDealsData, setAllDealsData] = useState({ 'title': [] });
    const [contentData, setContentData] = useState({});
    const [followUp, setFollowUp] = useState("");
    const [followUpDate, setFollowUpDate] = useState(null);
    const [success, setSuccess] = useState(false);
    const [followUpData, setFollowUpData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [showSessionPopupup, setShowSessionPopupup] = useState(false);
    const [lookup, setLookUp] = useState({});
    const [filter, setFilter] = useState([]);
    const [selecteddata, setSelectedData] = useState({});
    const [selected, setSelected] = useState([]);
    const [commentsError, setCommentsError] = useState("");
    const [adderror, setAddError] = useState("");
    const [uniqueErr, setuniqueErr] = useState("");


    const [DealFilesData, setDealFilesData] = useState({});
    const [fileDocName, setFileDocName] = useState("");
    const [playContent, setPlayContent] = useState({});
    const [type, setType] = useState(false);
    const [showDoc, setshowDoc] = useState(false);
    const [showUpload, setShowUpload] = useState(false); 
    const [restrict, setRestrict] = useState(false);
    const [limitReached, setLimitReached] = useState(false);

    const [fileNameerror, setFileNameError] = useState(false);
    const [fileName, setFileName] = useState("");
    const [showDocAlert, setShowDocAlert] = useState(false); 
    const [activeDeleteId, setActiveDeleteId] = useState("");
    const [deleteFile, setDeleteFile] = useState(false);


    const [languagesData, setlanguagesData] = useState({});
    const [countryData, setCountryData] = useState({});
    const [videoformatData, setVideoformatData] = useState({});
    const [rightsData, setRightsData] = useState({});
    const [errors, setErrors] = useState({});
    const [categoryName, setCategoryName] = useState([]);
    const [searchResult, setSearchResult] = useState({});
    const [datasuccess, setDataSuccess] = useState(false);
    const [todate, setToDate] = useState("");
    const [invalidDeal, setInvalidDeal] = useState(false);
    const [companiesData, setCompaniesData] = useState([]);
    const [loaderEnable, setLoaderEnable] = useState(false);
    const [ShowAdditionalComm, setShowAdditionalComm] = useState(false);
    const [activeCommentsData, setActiveCommentsData] = useState("");
    const [titleerror, setTitleError] = useState("");
    const [duplicateFlag, setDuplicateFlag] = useState(false);
    const [isLoadingValues, setIsLoadingValues] = useState(false);

    const [deleteErrPopup, setDeleteErrPopup] = useState(false);
    const { route, setRoute, setCurrentPage,activeTab, setActiveTab,navigateToggle, setNavigateToggle, setRowsPerPage, usePrevious, setActiveMenuId,userData, GetTimeActivity } = useContext(contentContext);


    const prevRoute = usePrevious(route)
    useEffect(() => {
        if (prevRoute != undefined && prevRoute != route) {
            setCurrentPage(1)
            setRowsPerPage(15)
        }
    }, [prevRoute]);
    useEffect(() => {
        const presentDate = new Date();
        const nextWeekDate = new Date(presentDate);
        nextWeekDate.setDate(presentDate.getDate() + 7);

        // setFollowUpDate(nextWeekDate);
        setFollowUpDate(moment(new Date(nextWeekDate)).format('YYYY-MM-DD'));
        // moment(new Date(nextWeekDate)).format('YYYY-MM-DD')
       getDealFilesFunction()
    }, []);


    const getDealFilesFunction = () => {
        getDealFiles(id).then((data) => {
            console.log("getClientcontent data", data);
            if (data.statusCode == 200) {
                // console.log(data.result, "---")
                if (data.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    setIsLoading(false)
                    setDealFilesData(data.result);
                }
            }
        }).catch((error) => {
            console.log(error);
        })
    }
    //   console.log('11111111',followUpDate ? followUpDate?.toDateString() : 'Loading...')
    //   console.log('222222222',followUpDate?.toISOString()?.split('T')[0])
    // console.log("contentDatqqqqqqqqa", contentData);
    // console.log("filterrrrr", filter);
    // console.log("searchResult", searchResult);
    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push("/");
        }
        setRoute("deal")
        GetAllDeals();
        GetLookUp();
        setActiveMenuId(300011)
        Categories();
        Companies();
        userActivity();
    }, []);
    const userActivity = () => {
        let path = window.location.pathname.split("/");
        const pageName = id != undefined ? path[path.length - 2] : path[path.length - 1];;
        var presentTime = moment();
        let payload;

        payload = {
            "userid": localStorage.getItem("userId"),
            "pagename": pageName,
            "pageurl": window.location.href,
            "starttime": presentTime,
            "useragent": JSON.parse(localStorage.getItem("loc"))

        };


        const previousId = localStorage.getItem("previousid");
        const urlLink = lambda + '/useractivity?appname=' + appname + (previousId ? "&previousid=" + previousId : "");


        axios({
            method: 'POST',
            url: urlLink,
            data: payload
        })
            .then(function (response) {
                if (response?.data?.statusCode === 200) {
                    localStorage.setItem("previousid", response?.data?.result)
                }
            });
    }
    // console.log('kkk')
    useEffect(() => {
        if (id) {
            getFollowup();
        }
    }, [success]);

    useEffect(() => {

        if (window.site === undefined) {
            setTimeout(() => {
                if (window.site && window.site.common && window.site.common.imagesCDN) {
                    setImg(window.site.common.imagesCDN)
                }
            }, 1000);
        }
        if (window.site && window.site.common && window.site.common.imagesCDN) {
            setImg(window.site.common.imagesCDN)
        }

    }, [window.site]);
    // useEffect(() => {
    //     if (allDealsData.requiredfromdate && allDealsData.requiredtodate) {

    //         if (allDealsData.requiredtodate < allDealsData.requiredfromdate) {
    //             setDataSuccess(true)
    //             // error["date"] ='To date cannot be earlier than From date';
    //             // formIsValid = false;
    //         }


    //     }
    // }, [allDealsData.requiredfromdate, allDealsData.requiredtodate]);


    const GetAllDeals = (e) => {
        setLoaderEnable(true)
        GetTimeActivity()
        const token = localStorage.getItem("token")
        const userid = localStorage.getItem("userId")
        axios({
            method: 'POST',
            url: lambda + '/clientenquiry?appname=' + appname + '&enquiryid=' + id + "&token=" + token + "&userid=" + localStorage.getItem("userId"),
        })
            .then(function (response) {
                if (response?.data?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else if (response?.data?.result?.data.length > 0) {


                    // console.log('aaaaaaaaa', response?.data?.result);
                    setAllDealsData({...response?.data?.result?.data[0],title:[]});
                    setContentData(response?.data?.result?.data[0].content);
                    setToDate(response?.data?.result?.data[0].requiredtodate)
                    setLoaderEnable(false)
                }
                else {
                    setInvalidDeal(true)
                }


            });
    }
    const handleClose = () => {
        setActiveCommentsData("")
        setShowAdditionalComm(false);
    };
    const onclickInvalid = () => {
        GetTimeActivity()
        setInvalidDeal(false)
        history.push('/dealmanagement')
    }

    const Categories = async () => {
        try {

            const response = await tmdbApi.getCategory({});
            if (response?.statusCode === 200) {
                if (response?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    //  setData(response?.result);
                    let arr = []
                    response?.result.data.forEach((item) => {
                        arr.push(item.name);
                    });

                    const arrOfObj = arr.map((item) => {
                        return { value: item, label: item };
                    });
                    setCategoryName(arrOfObj);
                }
            }
        } catch {
            console.log("error");
        }
    };

    const getFollowup = (e) => {
        GetTimeActivity()
        const token = localStorage.getItem("token")
        axios({
            method: 'GET',
            url: lambda + '/enquiryFollowup?appname=' + appname + '&enquiryid=' + id + "&token=" + token,
        })
            .then(function (response) {
                if (response?.data?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    setFollowUpData(response?.data?.result?.data)
                }

            });
    }
    const onClickSwitchTab = (e, typeOfTab) => {
        GetTimeActivity()
        setActiveTab(typeOfTab)

    }

    const handleChange = (e) => {
        GetTimeActivity()
        const { name, value } = e.target;

        if (name === "followupdate") {
            const selectedDate = new Date(value);
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Set time to midnight for comparison

            if (selectedDate >= today) {
                // Valid date, clear the error
                let updatedErrors = { ...errors };
                delete updatedErrors.followupdate;
                setErrors(updatedErrors);
                setFollowUpDate(value)
            } else {
                // Invalid date, set an error message
                setErrors({ ...errors, followupdate: 'Please select a date from today onwards' });
            }
        }
        if (name === "comments") {
            setCommentsError("")

        }
        // Update the form data
        setAllDealsData({ ...allDealsData, [name]: value });
    };
    const handleFollowUp = (e) => {
        GetTimeActivity()
        if (e.target.name === "followUp") {
            setFollowUp(e.target.value)
        }
        let error = { ...errors }
        if (e.target.value.trim() === "") {

            error[e.target.name] = "Follow up is required"
            setErrors(error)
        } else {
            delete error[e.target.name]
            setErrors(error)
        }
    }
    const Companies = async (e) => {
        GetTimeActivity()

        const token = localStorage.getItem("token")
        const userid = localStorage.getItem("userId")

        //  setcurrentPage(1);
        let obj = { sortby: 'companyname' }
        axios({
            method: 'POST',
            url: lambda + '/companies?appname=' + appname + "&token=" + token + "&userid=" + userid,
            data: obj
        })
            .then(function (response) {
                // console.log("response", response?.data?.result?.data);
                if (response?.data?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    setCompaniesData(response?.data?.result?.data);
                }
            }).catch((error) => {
                console.log(error);
            });
    }
    const returnArray = (arr) => {
        let arr2 = []
        //  console.log(arr);
        arr.map((eachItem) => {
            arr2.push(eachItem.value)
        })
        // console.log(arr2)
        return arr2
    }
    const returnArray1 = (arr) => {
        let arr2 = []
        //  console.log(arr);
        arr.map((eachItem) => {
            arr2.push(eachItem.value.toString())
        })
        // console.log(arr2)
        return arr2
    }
    // console.log("contentData------------>", contentData);
    const handleCheckTitle = (selectedData) => {
        let valid = true
        GetTimeActivity()
        // console.log("selected------------>", selectedData, contentData);
        if (selectedData.title && selectedData.title.length > 0) {
            selectedData.title?.map((eachItem) => {
                // console.log("each", eachItem)
                contentData && contentData?.map((each) => {
                    if (each.title === eachItem.value) {
                        // console.log("eachhhhhhhhh", eachItem, each);
                        valid = false
                    }

                })

            })
        } else if (selectedData.title && selectedData.title.length <= 0) {
            valid = false
        }
        return valid;
    }

    const handleChangeMultiSelect = (selected, key) => {
        GetTimeActivity()
        let newContent = Object.assign({}, allDealsData);
        if (key === 'title') {
            // console.log(selected, "selected")
            setTitleError("")
            setuniqueErr("")
            // let selectedArray = returnArray(selected);
            setSelectedData(selected);
            setAllDealsData({ ...newContent, [key]: selected });
            // setAllDealsData({ ...newContent, 'title': [...allDealsData?.title, selected[selected.length -1]] });

            setFilter({ ...filter, contenttitle: selected.value })
            // handleCheckTitle(selected);
        } else if (key == 'companyid') {
            let selectedArray = returnArray1(selected);
            // setAllDealsData({ ...filter, [key]: selectedArray });
            setFilter({ ...filter, [key]: selectedArray, contenttitle: [] })
            getOptionData(filter.category, selectedArray);
            setSelected([])
            setTitleError("")
            setuniqueErr("")
        }
        else {
            let selectedArray = returnArray(selected);


            setAllDealsData({ ...newContent, [key]: selectedArray });

            if (key === "category") {
                setFilter({ ...filter, category: selectedArray })
                setTitleError("")
                setuniqueErr("")
                getOptionData(selectedArray, filter.companyid);
                if (selectedArray <= 1) {
                    // setAllDealsData({ ...allDealsData, title: [] })
                    setSelected([])
                    setFilter({ ...filter, contenttitle: [], comments: "", category: [] })
                }
                // else if(selectedArray <= 0){
                //     setFilter({ ...filter, })
                // }
            }
        }


    }
    const CustomOption = ({ innerProps, label }) => (
        <div {...innerProps}>
          <input type="checkbox" checked={innerProps.isSelected} onChange={() => {}} />
          <label>{label}</label>
        </div>
      );
    const getOptionData = (selectedArray, companyid) => {
        setIsLoadingValues(true)
        GetTimeActivity()
        setLoaderEnable(true);
        const token = localStorage.getItem("token")
        const linkUrl = `${lambda}/content?appname=${appname}&token=${token}&sortBy=title&userid=${localStorage.getItem("userId")}`;

        //  console.log("filter",selectedArray);

        let categoryobj;
        if (companyid && companyid.length > 0) {
            categoryobj = { category: selectedArray, status: ["ACTIVE", "OFFLINE"], companyid: companyid }
        } else {
            categoryobj = { category: selectedArray, status: ["ACTIVE", "OFFLINE"] }
        }

        axios({
            method: 'POST',
            url: linkUrl,
            data: categoryobj,
        })
            .then(function (response) {
                // console.log("res1", response)
                setSearchResult(response?.data?.result?.data)
                setLoaderEnable(false);
                setIsLoadingValues(false);
            }).catch((err) => { setIsLoadingValues(false); });
    }
    // console.log("allDealsData-----------", allDealsData)

    const handleAdd = () => {
        if ( allDealsData?.title?.length > (100 - contentData?.length)){
            setLimitReached(true)
        }else{

        
        let valid = handleCheckTitle(allDealsData);
        setAddError("")
        GetTimeActivity()
        // console.log("allDealsData-----------", allDealsData)
        let obj = {}
        let arr = [...contentData]
        if (valid === true) {
            if (allDealsData?.title === undefined || allDealsData?.title === "" || allDealsData?.title == null) {
                setTitleError("Select a title before adding")
            }
            else {
                allDealsData?.title?.map((eachItem) => {
                    // console.log("eachItem",eachItem)
                    obj = {
                        category: eachItem.category,
                        contentid: eachItem.contentid,
                        title: eachItem.value,
                        portraitimage: eachItem.portraitimage,
                        landscapeimage: eachItem.landscapeimage,
                        comments: allDealsData.addcomments,
                        clientid: allDealsData.clientid
                    }
                    arr.push(obj);
                })

                if (allDealsData.category !== "") {
                    setContentData(arr);
                }
                setSelected([])
                setAllDealsData({ ...allDealsData, contentid: "", portraitimage: "", title: [], addcomments: "" })
                setFilter({ ...filter, contenttitle: [], addcomments: "" })
                setTitleError("")
                setuniqueErr("")
                setCommentsError("")
            }
        }
        else {
            if (allDealsData?.title === undefined || allDealsData?.title === "" || allDealsData?.title.length <= 0) {
                setTitleError("Please Select Titles");
                return
            } else {
                setuniqueErr("Title(s) already exists. Please select unique title(s).")
            }

        }
    }
    }

    const handleUpdateDeals = async (e) => {
        GetTimeActivity()
        setIsLoading(true)
        try {
            const response = await tmdbApi.UpdateDeals({
                // "followupdate": allDealsData.followupdate,
                "followupdate": followUpDate,
                "status": allDealsData.status === undefined ? "INPROGRESS" : allDealsData.status,
                "enquiryid": id,
                "content": contentData,
                "queries": allDealsData.queries ? allDealsData.queries?.trim() : "",
                "request": allDealsData.request ? allDealsData.request?.trim() : "",
                "rightsAvailable": allDealsData.rightsAvailable ? allDealsData.rightsAvailable : "",
                "requiredtodate": allDealsData.requiredtodate ? allDealsData.requiredtodate : "",
                "requiredfromdate": allDealsData.requiredfromdate ? allDealsData.requiredfromdate : "",
                "exclusive": allDealsData.exclusive ? allDealsData.exclusive : "",
                "regionsrequired": allDealsData.regionsrequired ? allDealsData.regionsrequired : "",
                "dubbinglanguagesrequired": allDealsData.dubbinglanguagesrequired ? allDealsData.dubbinglanguagesrequired : "",
                "videoformatsrequired": allDealsData.videoformatsrequired ? allDealsData.videoformatsrequired : "",
                "clientid": allDealsData.clientid,
                "clientname": allDealsData.clientname,
                "totalbudget": allDealsData?.totalbudget ?? "",
                "clienttype": allDealsData.type,
                "companyid": allDealsData.companyid,
                "comments": allDealsData?.comments ? allDealsData?.comments?.trim() : ""
            });
            // console.log(response?.result.result);
            if (response?.statusCode === 200) {
                if (response?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    setFollowUp("")
                    setSuccess(true);
                    setIsLoading(false)
                }
            }

        } catch {
            console.log("error");
        }
    }

    const updateFollowUp = async (e) => {
        GetTimeActivity()
        try {
            const response = await tmdbApi.UpdateFollowupDeals({
                "followup": followUp,
                "enquiryid": id,
                "followupdate": followUpDate
            });
            // console.log(response?.result.result);
            if (response?.statusCode === 200) {
                if (response?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    const presentDate = new Date();
                    const nextWeekDate = new Date(presentDate);
                    nextWeekDate.setDate(presentDate.getDate() + 7);

                    setFollowUpDate(nextWeekDate);
                }

            }

        } catch {
            console.log("error");
        }
    }

    // console.log('content data',contentData)
    // console.log(' allDealsData',allDealsData)
    const handleUpdate = (e) => {
        GetTimeActivity()
        setAddError("")
        if (followUp.trim() === "") {
            let err = { ...errors }
            err['followUp'] = "Follow up is required"
            setErrors(err)
        } else {
            if((allDealsData?.title !== undefined && allDealsData?.title.length > 0) && contentData.length <= 0){
                setAddError("Please Add Title");
                    // formIsValid = false;
            }else{
            // if (contentData.length <= 0) {
            //     setDeleteErrPopup(true)
            // }
            if(((contentData.length <= 0 )) && (allDealsData?.comments === undefined || allDealsData?.comments.trim() === "" || allDealsData?.comments.length <= 0)){
                setCommentsError("Please add comments as titles have not been selected yet.")
                // formIsValid = false;
            
            }
            else if (allDealsData?.requiredtodate < allDealsData?.requiredfromdate) {
                    setDataSuccess(true)
                }
            else if(contentData.length > 100){
                    setLimitReached(true)
                 
                }
            else {
                handleUpdateDeals();
                updateFollowUp();
            }
        }
        }

    }
    const handleShow = (e, value) => {
        setShowAdditionalComm(true)
        setActiveCommentsData(value)

    }
    const handleBack = () => {
        GetTimeActivity()
        setActiveTab("details")
      
       if(navigateToggle === "alldeals"){
         history.push('/dealmanagement')
       }else if(navigateToggle === "leads"){
        history.push('/leadmanagement')
       }else{
        history.goBack()
       }
        // history.push({
        //     pathname: "/dealmanagement",
        //     state: { search: true }
        // });
    }

    function onConfirm() {
        GetTimeActivity()
        setSuccess(false);

    };
    function onConfirm1() {
        GetTimeActivity()
        setDataSuccess(false);
        setAllDealsData({ ...allDealsData, requiredtodate: todate })

    };
    function onConfirm2() {
        GetTimeActivity()
        setLimitReached(false);
        // setAllDealsData({ ...allDealsData, requiredtodate: "" })

    };
    const GetLookUp = async (e) => {
        GetTimeActivity()
        try {
            let arrayType = ["country", "videoformat", "language", "territories"];

            const response = await tmdbApi.getLookUp({
                // type: arrayType

            });
            // console.log(response);
            if (response?.statusCode == 200) {
                let arrayType = ["country", "videoformat", "language", "territories", "rights"];
                let lookupsData = response?.result.data || []
                // console.log("lookupsData", lookupsData);
                arrayType.map((eachItem) => {
                    let arr1 = []
                    lookupsData.map((item1) => {
                        if (item1.type == eachItem) {
                            arr1.push(item1.name)
                        }
                    });
                    lookup[eachItem] = arr1
                    setLookUp({ ...lookup });
                })
                // console.log('loookup', lookup)
                const countryObj = prepareObject(lookup.country)
                const territoriesObj = prepareObject(lookup.territories)
                // console.log("countryObj", countryObj)
                // console.log("territoriesObjt", territoriesObj)
                setCountryData([...countryObj, ...territoriesObj])
                const languagesObj = prepareObject(lookup.language)
                setlanguagesData(languagesObj)
                const videoformatObj = prepareObject(lookup.videoformat)
                setVideoformatData(videoformatObj)
                const rightsObj = prepareObject(lookup.rights)
                setRightsData(rightsObj)

            }

        } catch {
            console.log("error");
        }
    }
    const prepareObject = (item) => {
        GetTimeActivity()
        const resultArr = item.map((eachItem) => { return { value: eachItem, label: eachItem }; })
        return resultArr
    }

    const handleDeleteDeal = (e, id) => {
        GetTimeActivity()
        const filteredArray = contentData.filter((eachItem) => eachItem.contentid != id);
        setContentData(filteredArray);
    }

    const handleSelectTitles = () => {
        // setAllDealsData({...allDealsData,title:[...allDealsData?.title,...selected]})
        // Use Set to remove duplicates based on object content
        const A = allDealsData?.title
        const B = selected
        const combinedSet = new Set([...A, ...B].map(obj => JSON.stringify(obj)));

        // Convert Set back to array
        const combinedArray = [...combinedSet].map(str => JSON.parse(str));

        console.log(combinedArray);
        setAllDealsData({...allDealsData,title:combinedArray})
        setSelected([])
        setTitleError("")
        setuniqueErr("")
     }
    const handleRemoveTitle = (e,id)=>{
        GetTimeActivity()
        const filteredArray = allDealsData?.title.filter((eachItem) => eachItem.contentid != id);
        setAllDealsData({...allDealsData, title :filteredArray});
        setTitleError("")
        setuniqueErr("")
    }
    console.log('allDealsData?.title?.leng data', allDealsData?.title?.length)

    const handleSearch = (e)=> {
       
        // if (e?.length > 100) {
          
        //     setLimitReached(true)
        // }else if (allDealsData?.title?.length > 100) {
          
        //     setLimitReached(true)
        // } else if (  e?.length > (100 - allDealsData?.title?.length)) {
           
        //     setLimitReached(true)
        // } else if (  e?.length > (100 - contentData?.length)) {
           
        //     setLimitReached(true)
        // } else if (  allDealsData?.title?.length > (100 - contentData?.length)) {
         
        //     setLimitReached(true)
        // }
        const totalLength = e?.length + allDealsData?.title?.length + contentData?.length;

        if (totalLength > 100) {
            setLimitReached(true);
        } else {
        GetTimeActivity()
        // console.log('e.target==>',e)
        setSelected(e)
        let newContent = Object.assign({}, allDealsData);
        // setAllDealsData({ ...newContent, "title": e });
        setTitleError("")
        setuniqueErr("")
        setAddError("")
        setCommentsError("")
        }
    }
    const handleOpen = (e, content) => {
        GetTimeActivity() 
        let source;
        source = window.site.common.imagesCDN + "/" + content.filepath;
        console.log("source", content, source)
        setFileDocName(content?.filename)
        setPlayContent(source);
        setshowDoc(true);
        setType('application/vnd.ms-excel');
        console.log("playercontent==========>", source);
    }
    // console.log('allDealsData',allDealsData)

    const handleUploadPopup = (e) => {
        GetTimeActivity() 
        setShowUpload(true);
    }
    const uploadS3 = async (e, type) => {
        console.log('upload s33')
        GetTimeActivity() 
        if (fileName !== "" && fileName !== undefined) {
          
            var fileData = new FormData();
            setIsLoading(true)
            var file = e.target.files[0];
            console.log("file", file);
            let filetype = file.type.split("/");
            console.log("filetype",filetype[0])
            if(filetype[0] === "video" || filetype[0] === "audio"){
                setRestrict(true)
            }else{
            let format = file.name.split(".");
            var uploadFilePath = "";
            var filename = e.target.files[0].name;
            var s3file = e.target.files[0];
            fileData.append(type[1], s3file);
            var bucket;

            var reader = new FileReader();
            reader.readAsDataURL(s3file);
            reader.onload = function (e) {
                var image = new Image();
                var fileformat = format[format.length - 1]
                const timestamp = Date.now();
                image.src = e.target.result;

                bucket = window.site.common.resourceBucket;

                var path = "uploads/" + id;
                uploadFilePath = appname + "/" + path + "/" + timestamp + "." + fileformat;

                console.log("uploadFilePath", uploadFilePath)
                let imagePath = window.site && window.site.common && window.site.common.imagesCDN;
                const token = localStorage.getItem("token")
                const userid = localStorage.getItem("userId")
                var data = { source_bucket: bucket, sourcepath: uploadFilePath }
                const DealFileId = uuid();
                var uploadObjPayload = {
                    "enquiryid": id,
                    "dealfileid": DealFileId,
                    "filepath": uploadFilePath,
                    "filename": fileName,
                    "createdby": {
                        "userid": userid,
                        "username": userData?.name
                    }
                }
               

                axios.post(lambda + '/uploadFiles?appname=' + appname + "&token=" + token +"&userid=" + userid , data, { type: 'application/json' })
                    .then((response) => {
                        console.log('api response')
                        if (response?.data && response?.data?.result) {
                            var url = response?.data?.result;

                            console.log("url", url);
                            axios.put(url, file, {
                                "headers": {
                                    "Content-Type": "multipart/form-data",
                                    "Accept": "/",
                                    "Cache-Control": "no-cache",
                                    "Accept-Encoding": "gzip, deflate",
                                    "Connection": "keep-alive",
                                    "cache-control": "no-cache"
                                }
                            })
                                .then((response) => {
                                    if (response && response?.status === 200) {
                                       axios({
                                            method: 'PUT',
                                            url: lambda + '/dealfiles?appname=' + appname + "&token=" + token + "&userid=" + userid+"&enquiryid="+id,
                                            data: uploadObjPayload,
                                        })
                                            .then(function (response) {
                                                if (response) {
                                                    // setBtnLoader(false)
                                                    setShowUpload(false);
                                                    setSuccess(true)
                                                    // ContentFiles();

                                                    getDealFilesFunction()
                                                    setFileName("");
                                                    ref.current.value = "";


                                                }
                                            }) .catch((err) => {
                                                // setBtnLoader(false)
                                                console.log(err);
                                            });


                                        //  handleUpdate();

                                    }
                                })
                                .catch((err) => {
                                    console.error.bind(err);
                                })
                        }
                    })
                    .catch((err) => {
                        console.error.bind(err);
                    })

            }
        }

        }
        else {
            setFileNameError(true);
        }
    }
    const handleDeleteFile = (e, dealFileID) => {
        GetTimeActivity() 
        const token = localStorage.getItem("token")
        const userid = localStorage.getItem("userId")
        setIsLoading(true)
        axios({
            method: 'DELETE',
            url: lambda + '/dealfiles?appname=' + appname + '&enquiryid=' + id + '&dealfileid=' + dealFileID + "&token=" + token + "&userid=" + userid,
        })
            .then(function (response) {
                if (response?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                } else {
                    setIsLoading(false);
                    setShowDocAlert(false);
                    setDeleteFile(true);
                    // getCompany();
                    getDealFilesFunction();
                    GetAllDeals();
                   
                }


            }).catch((err)=>{
                console.log('error',err);
                setIsLoading(false);
            });

    }
    const handleDeleteDoc= (e,id)=>{
        GetTimeActivity() 
        // console.log('iddddd',id)
        setActiveDeleteId(id)
        setShowDocAlert(true);
       
    }
    console.log("searchResult len----->", searchResult);
    console.log("selected len----->", selected);
    console.log("allDealsData len----->", allDealsData?.title);

    return (
        <>
            {showSessionPopupup && <SessionPopup />}
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <SweetAlert show={invalidDeal}
                    custom
                    confirmBtnText="ok"
                    confirmBtnBsStyle="primary"
                    title={"Deal Not Found"}
                    onConfirm={e => onclickInvalid()}
                >
                </SweetAlert>
                <Modal show={ShowAdditionalComm} onHide={handleClose} className="modal title-des-popup">
                    <Modal.Header closeButton>
                        <Modal.Title>ADDITIONAL COMMENTS</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <p style={{ whiteSpace: "pre-line" }}>{activeCommentsData}</p>

                    </Modal.Body>


                </Modal>

                {!invalidDeal &&
                    <div className="main-content user-management add-client view-deals edit-deals">

                        <div className="page-content ">
                            <div className="container-fluid">



                                <div className="row mb-4 breadcrumb">
                                    <div className="col-lg-12">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1">
                                                <h4 className="mb-2 card-title">EDIT DEALS</h4>
                                                <p className="menu-path">Deal Management / <b>Edit Deals</b></p>
                                            </div>
                                            <div>
                                                <a onClick={handleBack} className="btn btn-primary">back</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="create-user-block">

                                    <div className="form-block">
                                        {loaderEnable && <TableLoader />}
                                        <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <a className={`nav-link ${activeTab == 'details' ? 'active' : ''}`} data-bs-toggle="tab" href="#home1" role="tab" aria-selected="false" onClick={(e) => onClickSwitchTab(e, "details")}>
                                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                                    <span className="d-none d-sm-block">DETAILS</span>
                                                </a>
                                            </li>

                                            <li className="nav-item" role="presentation">
                                                 <a className={`nav-link ${activeTab == 'documents' ? 'active' : ''}`} data-bs-toggle="tab" href="#profile1" role="tab" aria-selected="true" tabIndex="-1" onClick={(e) => onClickSwitchTab(e, "documents")}>
                                                     <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                                     <span className="d-none d-sm-block">DOCUMENTS</span>
                                                 </a>
                                             </li> 
                                          
                                            <li className="nav-item" role="presentation">
                                                <a className={`nav-link ${activeTab == 'followup' ? 'active' : ''}`} data-bs-toggle="tab" href="#messages1" role="tab" aria-selected="false" tabIndex="-1" onClick={(e) => onClickSwitchTab(e, "followup")}>
                                                    <span className="d-block d-sm-none"><i className="far fa-envelope"></i></span>
                                                    <span className="d-none d-sm-block">FOLLOW UP</span>
                                                </a>
                                            </li>
                                          


                                        </ul>
                                        <div className="tab-content p-3 text-muted">
                                            <div className={`tab-pane ${activeTab == 'details' ? 'active show' : ''}`}  id="home1" role="tabpanel">
                                                {Object.keys(allDealsData).length > 0 ?
                                                    <>
                                                        <div className="row">
                                                            <h5 className="font-size-14"><i className="mdi mdi-arrow-right"></i> Buyer details</h5>
                                                            <div className="col-md-4">
                                                                <div className="mb-3 input-field">
                                                                    <label className="form-label form-label">Buyer Name</label>

                                                                    <input id="email" name="buyer" placeholder="buyer" type="text" className="form-control" value={allDealsData && allDealsData.clientname} disabled

                                                                    />

                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">

                                                                <div className="input-field">
                                                                    <label htmlFor="example-text-input" className="col-form-label">Company Name</label>
                                                                    <input className="form-control contact-number" type="text" name="title" value={allDealsData?.companyname && allDealsData?.companyname[0]} disabled placeholder="company name" id="example-email-input" />

                                                                </div>

                                                            </div>
                                                            <div className="col-md-4">
                                                                {allDealsData?.accountmanager != undefined && allDealsData?.accountmanager != "" &&
                                                                    <div className="input-field">
                                                                        <label htmlFor="example-text-input" className="col-form-label">Account Manager</label>
                                                                        <input className="form-control contact-number" type="text" name="title" value={allDealsData?.accountmanager} disabled placeholder="Account manager" id="example-email-input" />

                                                                    </div>}
                                                            </div>

                                                        </div>
                                                        <div className="row table-data">
                                                            <h5 className="font-size-14"><i className="mdi mdi-arrow-right"></i> Add Titles</h5>
                                                            <div className="col-md-6">
                                                                <div className="mb-3 input-field">
                                                                    <label className="form-label form-label">Category</label>

                                                                    <Select isMulti={true}
                                                                    //  closeMenuOnSelect={false}
                                                                        placeholder='Select category'
                                                                        onChange={(e) => handleChangeMultiSelect(e, 'category')}
                                                                        options={categoryName}
                                                                        value={filter && filter.category && filter.category?.map((eachItem) => { return { value: eachItem, label: eachItem } })}
                                                                    // }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="mb-3 input-field">
                                                                    <label className="form-label form-label">Seller Name</label>
                                                                    <Select
                                                                     closeMenuOnSelect={false}
                                                                        isMulti={true}
                                                                        placeholder='Select Company name'
                                                                        onChange={(e) => handleChangeMultiSelect(e, 'companyid')}
                                                                        options={companiesData && companiesData.map((eachItem) => { return { value: eachItem.companyid, label: eachItem.companyname } })}
                                                                        value={filter && filter.companyname && filter.companyname?.map((eachItem) => { return { value: eachItem.companyid, label: eachItem.companyname } })}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-11 search_title">
                                                                <div className="input-field">
                                                                    <label htmlFor="example-text-input" className="col-form-label">Search title</label>
{/* 

                                                                    <Select isMulti={true}
                                                                        isLoading={isLoadingValues}
                                                                        placeholder='Select title'
                                                                        closeMenuOnSelect={false}
                                                                        // components={{ Option: CustomOption }}
                                                                        onChange={(e) => handleChangeMultiSelect(e, 'title')}
                                                                        options={searchResult && searchResult.length > 0 ? searchResult.map((eachItem) => { return { value: eachItem.title, label: eachItem.title, portraitimage: eachItem.portraitimage, landscapeimage: eachItem.landscapeimage, contentid: eachItem.contentid, category: eachItem.category } }) : []}

                                                                        value={filter && filter.contenttitle && filter.contenttitle.length > 0 && filter.contenttitle.map((eachItem) => { return { value: eachItem, label: eachItem } })

                                                                        }
                                                                    />
                                                                   */}
                                                                    {/* <Multiselect
                                                                        //   isObject={false}
                                                                        displayValue="label"
                                                                        // placeholder="Select title"
                                                                        isDisabled={isLoadingValues}
                                                                        placeholder={isLoadingValues ? 'Loading...' : 'Select title'}

                                                                        onKeyPressFn={function noRefCheck() { }}
                                                                        onRemove={(e) => handleChangeMultiSelect(e, 'title')}
                                                                        onSearch={function noRefCheck() { }}
                                                                        onSelect={(e) => handleChangeMultiSelect(e, 'title')}
                                                                        options={searchResult && searchResult.length > 0 ? searchResult.map((eachItem) => { return { value: eachItem.title, label: eachItem.title, portraitimage: eachItem.portraitimage, landscapeimage: eachItem.landscapeimage, contentid: eachItem.contentid, category: eachItem.category } }) : []}
                                                                        showCheckbox
                                                                        selectedValues={filter && filter.contenttitle && filter.contenttitle.length > 0 && filter.contenttitle.map((eachItem) => { return { value: eachItem, label: eachItem } })}
                                                                    /> */}
                                                                    <MultiSelect

                                                                        // options={searchResult && searchResult.length > 0 ? searchResult : []}
                                                                   
                                                                       
                                                                        options={searchResult && searchResult.length > 0 ? searchResult.map((eachItem) => { return { value: eachItem.title, label: eachItem.title, portraitimage: eachItem.portraitimage, landscapeimage: eachItem.landscapeimage, contentid: eachItem.contentid, category: eachItem.category } }).filter((objA) => !allDealsData?.title?.some((objB) => objB.contentid === objA.contentid)) : []}

                                                                        value={selected}
                                                                        isLoading={isLoadingValues}
                                                                        className="search-title"
                                                                        // hasSelectAll={true}
                                                                        selectAllLabel="Select All Titles"
                                                                        // shouldToggleOnHover={true}
                                                                        // onChange={setSelected}
                                                                        onChange={(e) => handleSearch(e)}
                                                                        labelledBy={"Select Title"}
                                                                    // isCreatable={true}
                                                                    />
                                                   
                                                                    {titleerror != "" ?
                                                                        <span className="errormsg" style={{
                                                                            fontWeight: 'bold',
                                                                            color: 'red',
                                                                        }}>{titleerror}</span> : ""
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div className="col-md-1 ok_btn">
                                                            <label className="form-label form-label">ok</label>
                                                                <a className="btn btn-primary"  onClick={handleSelectTitles}>ok</a>
                                                                    </div>
                                            <div className="col-md-12 ">
                                                <div className="mb-3 input-field">
                                                    <label className="form-label form-label">
                                                        Titles Selected {allDealsData?.title?.length > 0 ? (
                                                           <>
                                                           (Total:  {allDealsData?.title?.length})
                                                         </>
                                                        ) : null}
                                                    </label>
                                                    <div className="selected_titles">
                                                        {allDealsData?.title && allDealsData?.title?.map((eachItem, index) => (
                                                            <span key={index} className="titles">
                                                                {eachItem.value}
                                                                <span className="mdi mdi-close" onClick={(e) => handleRemoveTitle(e, eachItem.contentid)}></span>
                                                            </span>
                                                        ))}
                                                    </div>
                                                    {uniqueErr != "" ?
                                                        <span className="errormsg" style={{
                                                            fontWeight: 'bold',
                                                            color: 'red',
                                                        }}>{uniqueErr}</span> : ""
                                                    }

                                                </div>
                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="mb-3 input-field">
                                                                    <label className="form-label form-label">Additional Comments</label>
                                                                    <textarea id="email" placeholder="Additional Comments" name="addcomments" type="text" className="form-control" value={allDealsData.addcomments} onChange={(e) => handleChange(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <a
                                                                    onClick={handleAdd}
                                                                    className="btn btn-primary">add</a>
                                                                            {adderror != "" ?
                                                <span className="errormsg" style={{
                                                    fontWeight: 'bold',
                                                    color: 'red',
                                                }}>{adderror}</span> : ""
                                            }
                                                            </div>
                                                    
                                                            <h5 className="font-size-14"><i className="mdi mdi-arrow-right"></i> titles Enquired {contentData?.length > 0 ? (
                                                           <>
                                                           (Total:  {contentData?.length})
                                                         </>
                                                        ) : null}</h5>
                                                            <div className="table-responsive">
                                                                <table className="table align-middle table-nowrap table-check">
                                                                    <thead className="table-light">
                                                                        <tr>
                                                                            <th className="align-middle">S.NO</th>
                                                                            <th className="align-middle">THUMBNAIL</th>
                                                                            <th className="align-middle">TITLE</th>
                                                                            <th className="align-middle">CATEGORY</th>
                                                                            <th className="align-middle">ADDITIONAL COMMENTS</th>
                                                                            <th className="align-middle">ACTIONS</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {contentData && contentData.length > 0 && contentData.map(function (item, i) {
                                                                            let picName = item?.mediaType === 'video' ? 'videoclip-defaulr' : item?.mediaType === 'audio' ? 'musicfile-default' : item?.mediaType === 'pdf' ? 'pdf-default' : item?.mediaType === 'doc' ? 'doc-defaulr' : 'img-default'
                                                                            let defaultImg = `${image}orasi/common/images/${picName}.jpg`;

                                                                            let imgUrl = (item?.portraitimage && item.portraitimage !== "")
                                                                            ? (item.portraitimage.startsWith("http") ? item.portraitimage : image + item.portraitimage)
                                                                            : (item?.landscapeimage && item.landscapeimage !== "")
                                                                              ? (item.landscapeimage.startsWith("http") ? item.landscapeimage : image + item.landscapeimage)
                                                                              : defaultImg;
                                                                          
                                                                            let clientUrl = window?.site?.client?.siteUrl;
                                                                            // console.log('item?.comments?.lenth',item?.comments?.lenth)
                                                                            return (
                                                                                <tr key={i}>
                                                                                    <td>{i + 1}</td>
                                                                                    <td><div className="d-flex justify-content-start"><a href={clientUrl + "/moreinfo/" + item.contentid + "&showoffline=true"} target="_blank"><img src={imgUrl + "?auto=compress,format&width=40"} /></a> </div></td>
                                                                                    <td>{item?.title}</td>
                                                                                    <td><div className="text-elipsis">{item && item?.category?.length > 1 ? item?.category.join(", ") : item?.category}</div></td>

                                                                                    <td><div className="text-elipsis">

                                                                                        {item?.comments ? item?.comments.substring(0, 50) + " " : ""}
                                                                                        {item?.comments?.length > 50 ?

                                                                                            (<button className="more-popup-btn"
                                                                                                onClick={e => handleShow(e, item?.comments)}>More</button>
                                                                                            ) : null}

                                                                                    </div></td>

                                                                                    <td><div className="d-flex" onClick={(e) => handleDeleteDeal(e, item.contentid)}><a className="text-danger action-button"><i className="mdi mdi-delete font-size-18"></i></a></div></td>
                                                                                </tr>

                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                        </div>

                                                        <div className="row">
                                                            <h5 className="font-size-14"><i className="mdi mdi-arrow-right"></i> enquiry details</h5>
                                                            <div className="col-md-4">
                                                                <div className="mb-3 input-field">
                                                                    <label className="form-label form-label">Required Rights</label>
                                                                    {/* <input id="email" name="rightsavaliable" placeholder="Rights Available" type="text" className="form-control" value={allDealsData && allDealsData.rightsAvailable && allDealsData.rightsAvailable.length > 1 ? allDealsData.rightsAvailable?.join(", ") : allDealsData.rightsAvailable} 
                                                            
                                                              /> */}

                                                                    <Select isMulti={true}
                                                                        placeholder='Required Rights'
                                                                        onChange={(e) => handleChangeMultiSelect(e, 'rightsAvailable')}
                                                                        options={rightsData}
                                                                        value={allDealsData && allDealsData.rightsAvailable && allDealsData.rightsAvailable.length > 0 ? allDealsData.rightsAvailable.length > 0 && allDealsData.rightsAvailable?.map((eachItem) => { return { value: eachItem, label: eachItem } }) : null}


                                                                    />

                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="mb-3 input-field">
                                                                    <label className="form-label form-label">rights available From Date</label>
                                                                    {/* <input id="email" name="rightavailableto" placeholder="Rights Available To Date" type="date" className="form-control" value={moment(allDealsData && allDealsData.requiredfromdate).utc().format("DD-MMM-YYYY")} disabled /> */}
                                                                    {/* <input id="email" name="rightsavaliable" placeholder="Rights Available From Date" type="text" className="form-control" value={allDealsData && allDealsData.requiredfromdate ? moment(allDealsData && allDealsData.requiredfromdate).utc().format("DD-MMM-YYYY") : null} /> */}
                                                                    <input id="email" name="requiredfromdate" placeholder="rightsAvailableFromDate" type="date" className="form-control" value={allDealsData.requiredfromdate} onChange={(e) => handleChange(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="mb-3 input-field">
                                                                    <label className="form-label form-label">rights available to Date</label>
                                                                    {/* <input id="email" name="rightavailableto" placeholder="Rights Available To Date" type="date" className="form-control" value={allDealsData.requiredtodate} disabled /> */}
                                                                    {/* <input id="email" name="rightsavaliable" placeholder="Rights Available To Date" type="text" className="form-control" value={allDealsData && allDealsData.requiredtodate ? moment(allDealsData && allDealsData.requiredtodate).utc().format("DD-MMM-YYYY") : null} /> */}
                                                                    <input id="email" name="requiredtodate" placeholder="rightsAvailableToDate" type="date" className="form-control" value={allDealsData.requiredtodate} onChange={(e) => handleChange(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="mb-3 input-field">
                                                                    <label className="form-label form-label">video format</label>
                                                                    {/* <input id="email" name="foreigntitle" placeholder="Video Format" type="text" className="form-control" value={allDealsData && allDealsData.videoformatsrequired && allDealsData.videoformatsrequired.length > 1 ? allDealsData.videoformatsrequired?.join(", ") : allDealsData.videoformatsrequired} /> */}
                                                                    <Select isMulti={true}
                                                                        placeholder='Video Format'
                                                                        onChange={(e) => handleChangeMultiSelect(e, 'videoformatsrequired')}
                                                                        options={videoformatData}
                                                                        value={allDealsData && allDealsData.videoformatsrequired && allDealsData.videoformatsrequired.length > 0 ? allDealsData.videoformatsrequired?.map((eachItem) => { return { value: eachItem, label: eachItem } }) : []}

                                                                        isClearable={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 ">
                                                                <div className="mb-3 input-field">
                                                                    <label className="form-label form-label">dubbing languages</label>
                                                                    {/* <input id="email" name="foreigntitle" placeholder="Dubbing Languages" type="text" className="form-control" value={allDealsData && allDealsData.dubbinglanguagesrequired && allDealsData.dubbinglanguagesrequired.length > 1 ? allDealsData.dubbinglanguagesrequired?.join(", ") : allDealsData.dubbinglanguagesrequired} /> */}
                                                                    <Select isMulti={true}
                                                                        placeholder='Dubbing Languages'
                                                                        onChange={(e) => handleChangeMultiSelect(e, 'dubbinglanguagesrequired')}
                                                                        options={languagesData}
                                                                        value={allDealsData && allDealsData.dubbinglanguagesrequired && allDealsData.dubbinglanguagesrequired.length > 0 ? allDealsData.dubbinglanguagesrequired?.map((eachItem) => { return { value: eachItem, label: eachItem } }) : []}

                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="mb-3 input-field">
                                                                    <label className="form-label form-label">exclusivity</label>
                                                                    {/* <input id="email" name="foreigntitle" placeholder="Exclusivity" type="text" className="form-control" value={allDealsData.exclusive} /> */}
                                                                    <select className="colorselect capitalize form-control" name="exclusive" onChange={(e) => handleChange(e)} value={allDealsData.exclusive}>
                                                                        <option value="">Select Type</option>

                                                                        <option value="Exclusive">Exclusive</option>
                                                                        <option value="Non-Exclusive">Non-Exclusive</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="mb-3 input-field">
                                                                    <label className="form-label form-label">Regions Required</label>
                                                                    {/* <input id="email" name="foreigntitle" placeholder="Regions Required" type="text" className="form-control" value={allDealsData && allDealsData.regionsrequired && allDealsData.regionsrequired.length > 1 ? allDealsData.regionsrequired?.join(", ") : allDealsData.regionsrequired} /> */}
                                                                    <Select isMulti={true}
                                                                        placeholder='Regions'
                                                                        onChange={(e) => handleChangeMultiSelect(e, 'regionsrequired')}
                                                                        options={countryData}
                                                                        value={allDealsData && allDealsData.regionsrequired && allDealsData.regionsrequired.length > 0 ? allDealsData.regionsrequired?.map((eachItem) => { return { value: eachItem, label: eachItem } }) : []} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="mb-3 input-field">
                                                                    <label className="form-label form-label">Total Budget (USD)</label>
                                                                    {/* <input id="email" name="totalbudget" placeholder="Enter Total Budget" className="form-control"
                                                                    
                                                                    type="text"
                                                                   
                                                                    value={formatAsCurrency(allDealsData?.totalbudget)}
                                                                    onChange={(e) => handleChange(e)} /> */}

                                                                    <CurrencyInput
                                                                        id="total-budget"
                                                                        name="totalbudget"
                                                                        className="form-control"
                                                                        label="Total Budget (USD)"
                                                                        placeholder="Enter Total Budget"
                                                                        value={allDealsData?.totalbudget} 
                                                                        defaultValue={allDealsData?.totalbudget} // Optional initial value
                                                                        decimalsLimit={2}  // Limit to 2 decimal places
                                                                        prefix="$"          // Add a dollar sign prefix
                                                                        onValueChange={(value, name, values) => {
                                                                            // Handle value changes here
                                                                            // console.log(value); // This will be the parsed number without formatting
                                                                            // console.log(values.formatted); // This will be the formatted value as a string
                                                                            setAllDealsData({ ...allDealsData, [name]: values.value }); // Update your state
                                                                        }}
                                                                    />

                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="mb-3 input-field">
                                                                    {/* <label className="form-label form-label">Total Budget (US$)</label>
                                                                    <input id="email" name="totalbudget" placeholder="Enter Total Budget" type="text" className="form-control" onChange={(e) => handleChange(e)} value={allDealsData?.totalbudget} /> */}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="mb-3 input-field">
                                                                    <label className="form-label form-label">Request</label>
                                                                    <textarea id="email" name="request" placeholder="Request" type="text" className="form-control" onChange={(e) => handleChange(e)} value={allDealsData.request} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="mb-3 input-field">
                                                                    <label className="form-label form-label">Queries</label>
                                                                    <textarea id="email" name="queries" placeholder="Queries" type="text" className="form-control" onChange={(e) => handleChange(e)} value={allDealsData.queries} />
                                                                </div>
                                                            </div>


                                                            <div className="col-md-4">
                                                                <div className="mb-3 input-field">
                                                                    <label className="form-label form-label">Comments</label>
                                                                    <textarea id="email" name="comments" placeholder="Comments" type="text" className="form-control" onChange={(e) => handleChange(e)} value={allDealsData.comments} />
                                                                    {commentsError != "" ?
                                                                        <span className="errormsg" style={{
                                                                            fontWeight: 'bold',
                                                                            color: 'red',
                                                                        }}>{commentsError}</span> : ""
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="row comment-section">
                                                            <div className="col-md-8">
                                                                <label className="verticalnav-address-input">FOLLOW UP<span className="required">*</span></label>
                                                                <textarea id="verticalnav-address-input" name="followUp" className="form-control" rows="2" value={followUp} onChange={(e) => handleFollowUp(e)} style={{ marginBottom: "10px" }}></textarea>
                                                                {errors.followUp && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.followUp}</span>}
                                                            </div>
                                                            <div className="col-md-4">

                                                                <label className="col-form-label">next followup Date<span className="required">*</span></label>
                                                                <input id="email" name="followupdate" placeholder="Release Date" type="date"  min={moment().format('YYYY-MM-DD')} value=
                                                                    {moment(followUpDate).format('YYYY-MM-DD')} onChange={(e) => handleChange(e)} className="form-control" />
                                                                {errors.followUpDate && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>{errors.followUpDate}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="row status">

                                                            <div className="col-md-3 justify-content-between ps-0">

                                                                <div className="input-field d-flex align-items-center">

                                                                    <label className="col-form-label">Status</label>

                                                                    <select className="form-select" name="status" value={allDealsData.status} onChange={(e) => handleChange(e)} >
                                                                        <option value="NEW">NEW</option>
                                                                        <option value="INPROGRESS">INPROGRESS</option>
                                                                        <option value="PARTIALLY CLOSED">PARTIALLY CLOSED</option>
                                                                        <option value="CLOSED">CLOSED</option>
                                                                        <option value="SUCCESSFULLY CLOSED">SUCCESSFULLY CLOSED</option>
                                                                    </select>


                                                                </div>

                                                            </div>


                                                            <div className="col-md-9 justify-content-end d-flex align-items-center">

                                                                <a className="btn btn-primary" onClick={handleUpdate}> {isLoading ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}UPDATE</a>


                                                            </div>


                                                        </div>
                                                    </>
                                                    : !showSessionPopupup && <div className="row"><Loader /></div>}
                                            </div>
                                            <div className={`tab-pane ${activeTab == 'documents' ? 'active show' : ''}`}  id="profile1" role="tabpanel">
                                                <div className="document-title">
                                                    <label >documents</label>
                                                    <a className="btn btn-primary" onClick={handleUploadPopup}><span className="material-icons">add</span>add</a>


                                                </div>
                                                {DealFilesData && DealFilesData.length > 0 ?
                                                    <div className="row mb-3 document-list">
                                                        {/* <div>No Documents Found</div> */}
                                                        {DealFilesData && DealFilesData.length > 0 && DealFilesData.map(function (item, i) {
                                                            return (
                                                                <div className="column5">
                                                                    <div className="document-cont">
                                                                        <div className="document-img"  style={{ cursor: 'pointer', textDecoration: 'none' }}onClick={(e) => handleOpen(e, item)}>
                                                                            <a className=""><img className="documentlogo" src={image+"orasi/common/images/doc-defaulr1.jpg?" + Config.compress} /></a>
                                                                        </div>
                                                                        <div className="documentTitle">
                                                                            <h3>{item.filename}</h3>
                                                                            <p>updated on  {moment(new Date(item.created)).format('DD-MM-YYYY')} </p>
                                                                            <button type="button" className="btn-success btn-rounded waves-effect waves-light mb-2 me-2" onClick={(e) => handleDeleteDoc(e,item.dealfileid)}>
                                                                                <span className="material-icons-outlined"> delete_outline</span></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}

                                                    </div>
                                                    : <div className="no-documents">
                                                        <span className="material-icons-outlined">description</span>
                                                        <p>No document has been found yet !</p>
                                                    </div>


                                                }


                                            </div>
                                            <div className={`tab-pane ${activeTab == 'followup' ? 'active show' : ''}`} id="messages1" role="tabpanel">
                                                <div className="mb-3 row">

                                                    {followUpData && followUpData.length > 0 ?
                                                        <div className="col-md-12">
                                                            <label >FOLLOW UP</label>
                                                            {followUpData && followUpData.length > 0 && followUpData.map(function (item, i) {

                                                                return (
                                                                    <div className="comments-block" key={i}>
                                                                        <div className="d-flex align-item-center justify-content-between">  <div> <label>Created on:</label> <p className="time">{item.created ? new Date(item.created).toLocaleDateString('en-IN', {
                                                                            day: 'numeric',
                                                                            month: 'short',
                                                                            year: 'numeric',
                                                                            // hour: 'numeric',
                                                                            // minute: 'numeric',
                                                                        }) : ""}</p>
                                                                        </div>
                                                                            <div>
                                                                                <label>Followup Date :</label><p className="time followup-date">
  {item.followupdate
    ? moment(item.followupdate).locale('en-in').format('D MMM YYYY')
    : ""}
</p>

                                                                            </div>
                                                                        </div>
                                                                        <p className="comments">{item.followup}</p>

                                                                    </div>
                                                                )
                                                            })}

                                                        </div> : <div div className="followups"><div className="no-documents">
                                                            <span className="material-icons-outlined">comment</span>
                                                            <p>No follow-ups were found</p>
                                                        </div></div>}

                                                </div>
                                            </div>
                                          
                                        </div>

                                    </div>

                                </div>


                            </div>
                        </div>
                        {/* modal title-des-popup */}

                        <Footer />
                        {showDoc && <FileViewer source={playContent} type={type} close={setshowDoc} name={fileDocName}/>}
                        <SweetAlert show={success}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Updated Successfully"}
                            onConfirm={e => onConfirm()}
                        >
                        </SweetAlert>

                        <SweetAlert show={datasuccess}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"To date cannot be earlier than From date"}
                            onConfirm={(e) => onConfirm1(e)}
                        >
                        </SweetAlert>
                        <SweetAlert show={deleteErrPopup}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Before updating, please make sure to select at least one title"}
                            onConfirm={(e) => setDeleteErrPopup(false)}
                        >
                        </SweetAlert>
                        <SweetAlert show={deleteFile}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"File Deleted Successfully"}
                            onConfirm={e =>  setDeleteFile(false)}
                        >
                        </SweetAlert>
                        <SweetAlert show={restrict}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"Invalid file type. please select valid file"}
                            onConfirm={e => {  setRestrict(false);setShowUpload(false);setIsLoading(false);setFileName("");}}
                        >
                        </SweetAlert>
                        <SweetAlert show={limitReached}
                            custom
                            confirmBtnText="ok"
                            confirmBtnBsStyle="primary"
                            title={"A maximum of 100 titles can be selected."}
                            onConfirm={(e) => onConfirm2(e)}
                        >
                        </SweetAlert>
                    </div>
                }

  {showUpload &&
                            <Modal show={true} className="seller-pop new-look-up add-document" >
                                {/* <button className="close-btn" onClick={(e) => setShowUpload(false)}><span className="material-icons">close</span></button>
                                <div className="col-md-3">
                                    <input name="filename" placeholder="Enter File Name" type="text" className="form-control" onChange={(e) => setFileName(e.target.value)} value={fileName} />
                                </div>
                                {fileName &&
                                    <><input type="file" accept="image/png, image/jpeg , image/*,audio/*,video/*,.pdf, .xls, .xlsx,.csv,.docx" name="landscape_logo_image" className="udisplay-none" onChange={e => uploadS3(e, ["1920*1080", "Image", "landscape_logo_image"])} onClick={(e) => { e.target.value = ''; }} ref={ref} />
                                        <button type="button" onClick={handleUpdate} className="btn-success waves-effect waves-light mb-2 me-2">
                                            save</button></>
                                } */}
                                <Modal.Header >
                                    <Modal.Title>Add Document</Modal.Title>
                                    <button className="close-btn" onClick={(e) => setShowUpload(false)}><span className="material-icons">close</span></button>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row">
                                        <div className="col-md-12 documents">
                                            <div className="mb-3 input-field">
                                                <label className="form-label form-label">Document Name<span className="required">*</span></label>
                                                <input name="filename" placeholder="Enter File Name" type="text" className="form-control" onChange={(e) => setFileName(e.target.value)} value={fileName} />
                                                {fileNameerror ?
                                                    <span className="errormsg" style={{
                                                        fontWeight: 'bold',
                                                        color: 'red',
                                                    }}>Please enter File Name</span> : ""
                                                }
                                            </div>
                                            <div className={`mb-3 input-field btn-gray ${(fileName===undefined|| fileName === "") ? 'disable pe-none':''}`}>
                                                <input type="file" name="upload" accept=".xls, .xlsx," className="udisplay-none" id="upload" onChange={e => uploadS3(e, ["1920*1080", "Image", "landscape_logo_image"])} onClick={(e) => { e.target.value = ''; }} ref={ref} disabled={fileName===undefined|| fileName === ""} />
                                                {isLoading ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : <span className="material-icons">upload</span>}upload document</div>
                                            <p>Only Excel documents are allowed.</p>

                                            {/* <a className="btn btn-primary" onClick={handleUpdate}>done</a> */}
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        }
                    
                        {showDocAlert && <Modal className="access-denied" show={true}>

                            <div className="modal-body enquiry-form">
                                <div className="container">
                                    <button className="close-btn" onClick={e => setShowDocAlert(false)}><span className="material-icons">close</span></button>
                                    <span className="material-icons access-denied-icon">delete_outline</span>
                                    <h3>Delete</h3>
                                    <p>This action cannot be undone.</p>
                                    <p>Are you sure you want to delete File?</p>
                                    <div className="popup-footer">
                                        <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e =>  {handleDeleteFile("e",activeDeleteId)}}> {isLoading ? (<img src={image+"orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"} className="loading-icon" />) : null}Yes, Delete</button>
                                    </div>
                                </div>
                            </div>

                        </Modal>}
            </div>

        </>
    );
};

export default EditDeals;
