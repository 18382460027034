/***
**Module Name: content dashboard
 **File Name :  contentmanage.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains content mamnagement details.
 ***/
 import React, { useState, useEffect, useContext } from "react";


 import Footer from "../../components/dashboard/footer";
 import Header from "../../components/dashboard/header";
 import Sidebar from "../../components/dashboard/sidebar";
 import tmdbApi from "../../api/tmdbApi";
 import { useHistory, Link, useLocation } from "react-router-dom";
 import axios from 'axios';
 import SweetAlert from 'react-bootstrap-sweetalert';
 import Button from 'react-bootstrap/Button';
 import moment from "moment";
 import Modal from 'react-bootstrap/Modal';
 import TableLoader from "../../components/tableLoader";
 import SessionPopup from "../SessionPopup"
 import AdvanceSearch from "./advanceSearch";
 import Loader from "../../components/loader";
 import Dropdown from 'react-bootstrap/Dropdown';
 import DataTable from 'react-data-table-component';
 import * as XLSX from 'xlsx';
 import * as FileSaver from 'file-saver';
 
 import { contentContext } from "../../context/contentContext";
 let { lambda, appname } = window.app
 
 
 
 
 
 const ContentMange = () => {
     const history = useHistory();
     const { state } = useLocation();
     const { search } = state || {};
     // const [toggle, setToggle] = useState(false);
     const [content, setContent] = useState("");
     // const [data, setData] = useState([]);
     const [success, setSuccess] = useState(false);
     const [isAlert, setIsAlert] = useState(false);
     const [isdelete, setDelete] = useState(false);
     const [item, setItem] = useState("");
     const [activeCheckId, setActiveCheckId] = useState("");
     const [isCompanyInclude, setIsCompanyInclude] = useState(false);
     const [exportLoad, setExportLoad] = useState(false);
     const [importLoad, setImportLoad] = useState(false);
     const[exportMsg,setExportMsg]=useState(false);
     
     const [activeImportCheckId, setActiveImportCheckId] = useState("");
 
     const [exportFlag, setExportFlag] = useState(true);
     
     const [exportData, setExportData] = useState({});
     //  const [importFlag, setImportFlag] = useState(false);
     const [importData, setImportData] = useState({});
 
     const [currentPageImport, setCurrentPageImport] = useState(1);
     const [currentPageExport, setCurrentPageEport] = useState(1);
     const [rowsPerPageImport, setRowsPerPageImport] = useState(15);
 
     const [rowsPerPageExport, setRowsPerPageExport] = useState(15);
 
     const [image, setImg] = useState('');
     const [flag, setFlag] = useState(false);
 
     const [loaderDelete, setLoaderDelete] = useState(false);
     const [tableErrMsg, setTableErrMsg] = useState(false);
     const [btnDisable, setBtnDisable] = useState(false);
     const [showSessionPopupup, setShowSessionPopupup] = useState(false);
     const [activeExportBtn, setActiveExportBtn] = useState("");
     const [selectAll, setSelectAll] = useState(false);
     // const [sortingPayload, setSortingPayload] = useState("");
 
     const { searchedFlag, setSearchedFlag,selectedItems,isChecked1, setIsChecked1, setSelectedItems, searchExport, setSearchExport, sortTableAlpha, arrow, sortTableByDate, contentsearch, setContentSearch, searchPayload, setActiveMenuObj, setSearchPayload, currentPage, setSelectedOptions, setMultiSelectFields, setActiveFieldsObj, setSelectedOptionsClientName, data, setData, rowsPerPage, setRowsPerPage, currentPageNew, setCurrentPage, route, setRoute, usePrevious, isLoading, setIsLoading, setActiveMenuId, pay, setPay, totalRows, setTotalRows, sortingPayload, setSortingPayload, importFlag, setImportFlag } = useContext(contentContext)
 
     const { initialCategoriesData1, userData, setInitialCategoriesData1, popup, setShowPopup, handleClosePopup, GetTimeActivity } = useContext(contentContext);
 
     const validateObj = userData && userData.permissions && userData.permissions.length > 0 && userData.permissions.filter(eachItem => eachItem.menu == "Content Management")
     const subValDashboard = validateObj && validateObj[0] && validateObj[0].dashboard
 
     const prevRoute = usePrevious(route)
     useEffect(() => {
         if (prevRoute != undefined && prevRoute != route) {
             console.log('use eefffect prevroute')
             setCurrentPage(1)
             setRowsPerPage(15)
             setImportFlag(false)
             setSelectedOptions([])
             setSortingPayload("")
             setContentSearch("")
             setSearchExport(false)
             setPay({})
             setMultiSelectFields({ dubbinglanguages: [], typeofrights: [], countryorigin: [], languages: [], typeofrights: [], genre: [], videoquality: [], certificate: [], subtitleslanguages: [], territoriesavailable: [], sport: [], musicgenre: [] })
             setActiveFieldsObj({ CookingshowActive: false, seriesActive: false, SportsActive: false, LiveEventActive: false, MusicActive: false })
             setSelectedOptionsClientName([])
             setSearchPayload({})
             setSearchedFlag(false);
         }
     }, [prevRoute]);
     const keyForRerender = `${rowsPerPage}_${data?.length}`;
     useEffect(() => {
         if (!localStorage.getItem("token")) {
             history.push("/");
         }
         setActiveMenuId(200001)
         setActiveMenuObj({
             "Client Management": false,
             "Contact Management": false,
             "Reports": false
         })
         setRoute("content")
         GetContentNew(currentPageNew)
         userActivity();
     }, []);
     //  console.log('importFlag--->1111',importFlag)
     const userActivity = () => {
         let path = window.location.pathname.split("/");
         const pageName = path[path.length - 1];
         var presentTime = moment();
         let payload;
 
         payload = {
             "userid": localStorage.getItem("userId"),
             "pagename": pageName,
             "pageurl": window.location.href,
             "starttime": presentTime,
             "useragent": JSON.parse(localStorage.getItem("loc"))
 
         };
 
 
         const previousId = localStorage.getItem("previousid");
         const urlLink = lambda + '/useractivity?appname=' + appname + (previousId ? "&previousid=" + previousId : "");
 
 
         axios({
             method: 'POST',
             url: urlLink,
             data: payload
         })
             .then(function (response) {
                 if (response?.data?.statusCode === 200) {
                     localStorage.setItem("previousid", response?.data?.result)
                 }
             });
     }
     const handleSelectAllChange = (isChecked) => {
        setSelectAll(isChecked);
    
        if (isChecked) {
            // Select all itemsetSelectedItems
           
            setSelectedItems(data.map((item) => item.contentid));
        } else {
            // Deselect all items
            setSelectedItems([]);
        }
    };
    const handleCheckboxChange = (item, isChecked) => {
        let updatedSelectedItems;
        if (isChecked) {
            updatedSelectedItems = [...selectedItems, item];
        } else {
            // Uncheck "Select All" if any individual item is unchecked
            updatedSelectedItems = selectedItems.filter((id) => id !== item);
            setSelectAll(false);
        }
        setSelectedItems(updatedSelectedItems);

        // Update "Select All" status if all items are selected
        if (updatedSelectedItems.length === data.length) {
            setSelectAll(true);
        }
    };
     const handleSort = (column, sortDirection) => {
 
         // console.log('handle sort', column)
         // console.log('sort direction', sortDirection)
 
         let name = column?.name.toLowerCase();
         name = name === "released" ? "releaseyear" : name === "videos" ? "videoAvailable" : name
         let direction = sortDirection === "asc" ? "ascending" : "descending"
         let payload = { "type": direction, "sortBy": name }
         setSortingPayload(payload)
 
     };
     useEffect(() => {
         if (sortingPayload) {
             GetContentNew(currentPageNew)
         }
     }, [sortingPayload])
 
 
 
     const handleEditContent = (e, id) => {
         // if( subValDashboard && subValDashboard.edit && subValDashboard.edit.enable === true){
         history.push("/editcontent/" + id);
         // }
 
     }
 
     const handleViewContent = (e, id) => {
         history.push("/viewcontent/" + id);
     }
 
     const columnsExport = [
         {
             name: 'File',
             selector: row => row?.exportId ?? "",
             sortable: true,
         },
         {
             name: 'Status',
             selector: row => (
                 row.status === "Inprogress" ? (
                     <>
                         <label>Inprogress</label>
                         <div className="progress">
                             <div className="progress-bar progress-bar-striped bg-info" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                         </div>
                     </>
                 ) : (
                     row.status
                 )
             ),
             sortable: true,
         },
         {
             name: 'Created',
             selector: row => row?.created,
             cell: props => props?.created ? new Date(props.created).toLocaleDateString('en-IN', {
                 day: '2-digit',
                 month: '2-digit',
                 year: '2-digit',
                 hour: 'numeric',
                 minute: 'numeric',
                 hour12: true,
             }) : '',
             sortable: true,
         },
         {
             name: 'Completed on',
             selector: row => row?.completedOn,
             cell: props => props?.completedOn ? new Date(props.completedOn).toLocaleDateString('en-IN', {
                 day: '2-digit',
                 month: '2-digit',
                 year: '2-digit',
                 hour: 'numeric',
                 minute: 'numeric',
                 hour12: true,
             }) : '',
             sortable: true,
         },
         {
             name: 'Type',
             selector: row => row?.type,
             sortable: true,
         },
         {
             name: 'Actions',
             cell: props => (
                 <div className="d-flex">
                     {props.status === "Inprogress" ? (
                         <a className="text-success action-button" onClick={e => handleRefresh(e, props)}>
                             <span className="material-icons-outlined text-warning">
                                 {activeCheckId === props.exportId ? (
                                     <img src="https://d9nwtjplhevo0.cloudfront.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon" />
                                 ) : (
                                     <span className="material-icons-outlined">refresh</span>
                                 )}
                             </span>
                         </a>
                     ) : (
                        //  <a href={`${window?.site?.common?.proxiesCloudFront}/${props.downloadLink}`} className="text-success action-button" download>
                        //      <span className="material-icons-outlined text-success">download</span>
                        //  </a>


                        <button className="text-success action-button">
                             <span
                                 className="material-icons-outlined text-success"
                                 style={{ cursor: 'pointer' }}
                                 onClick={() => handleDownload(`${window?.site?.common?.proxiesCloudFront}/${props.downloadLink}`, props)}
                             >
                                 download
                             </span>
                             </button>
                     )}
                     <a className="text-danger action-button" onClick={e => handleDeleteExportFilePopup(e, props.exportId)}>
                         <span className="material-icons-outlined text-danger">delete_outline</span>
                     </a>
                 </div>
             ),
         },
     ];
 
     const columnsImport = [
         // {
         //     name: '',
         //     selector: "",
         //     sortable: true,
         // },
         {
             name: 'Import Id',
             selector: row => row?.importId ?? "",
             sortable: true,
         },
         {
             name: 'User Name',
             selector: row => row?.importedBy?.username ?? '',
             sortable: true,
         },
         {
             name: 'Start At',
             selector: row => row?.created
             ,
             cell: (props) => props?.created ? new Date(props?.created).toLocaleDateString('en-IN', {
                 day: '2-digit',
                 month: '2-digit',
                 year: '2-digit',
                 hour: 'numeric',
                 minute: 'numeric',
                 hour12: true,
             }) : ''
             ,
             sortable: true,
             // sortFunction: (a, b) => moment(a.created).diff(b.created),
         },
         {
             name: 'End At',
             selector: row => row?.completedOn,
             cell: (props) => props?.completedOn ? new Date(props?.completedOn).toLocaleDateString('en-IN', {
                 day: '2-digit',
                 month: '2-digit',
                 year: '2-digit',
                 hour: 'numeric',
                 minute: 'numeric',
                 hour12: true,
             }) : ''
             ,
             sortable: true,
             //  sortFunction: (a, b) => moment(a.completedOn).diff(b.completedOn),
         },
 
         {
             name: 'Status',
             selector: row => row?.status === "INPROGRRESS" ? 'In Progress' : row?.status,
             sortable: true,
         },
         {
             name: 'Added',
             selector: row => row?.result?.data?.[0]?.insertedCount ?? "",
             sortable: true,
         },
         {
             name: 'Updated',
             selector: row => row?.result?.data?.[0]?.updatedCount ?? "",
             sortable: true,
         },
         {
             name: 'Rejected',
             selector: row => row?.result?.data?.[0]?.rejectedCount ?? "",
             sortable: true,
         },
         {
             name: 'Total',
             selector: row => row?.result?.data?.[0]?.count ?? "",
             sortable: true,
         },
         {
             name: 'Actions',
             cell: (props) => <div className="d-flex">
                 {props.status === "INPROGRESS" ? <a className="text-success action-button" onClick={(e) => handleImportRefresh(e, props.importId)}>
                     <span className="material-icons-outlined text-warning">
                         {activeImportCheckId === props.importId ?
                             (<img src="https://d9nwtjplhevo0.cloudfront.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"
                                 className="loading-icon" />) :
 
 
                             <span className="material-icons-outlined">refresh</span>}
                     </span></a>
                     : props?.result?.data?.[0]?.rejectedCount > 0 ? <a className="text-success action-button"
                         //  onClick={(e) => handleDownload(e, item.downloadLink)}
                         onClick={(e) => fetchImportData(e, props?.importId)}
                     ><span className="material-icons-outlined text-success">download</span></a> : null}
 
 
             </div>,
         },
     ]
    //  console.log("selectedItems-------------->", selectedItems)
     const columns = [
        // Conditionally include the checkbox column
       
      
        {
          name: 'Image',
          cell: (props) => (
            <img
              src={
                props.portraitimage
                  ? props.portraitimage.startsWith('http')
                    ? props.portraitimage
                    : `${image}${props.portraitimage}`
                  : props.landscapeimage
                  ? props.landscapeimage.startsWith('http')
                    ? props.landscapeimage
                    : `${image}${props.landscapeimage}`
                  : `https://d9nwtjplhevo0.cloudfront.net/orasi/common/images/${
                      props.mediaType === 'video'
                        ? 'videoclip-default'
                        : props.mediaType === 'audio'
                        ? 'musicfile-default'
                        : props.mediaType === 'pdf'
                        ? 'pdf-default'
                        : props.mediaType === 'doc'
                        ? 'doc-default'
                        : 'img-default'
                    }.jpg?auto=compress,format&width=40`
              }
              alt='Image'
            />
          ),
          sortable: false,
        },
        {
          name: 'Title',
          selector: (row) => row?.title ?? '',
          sortable: true,
        },
        {
          name: 'Category',
          selector: (row) => (row.category && Array.isArray(row.category) ? row.category.join(', ') : ''),
          sortable: true,
        },
        {
          name: 'Videos',
          cell: (props) => (
            <div className="availability">
              <span className="material-symbols-outlined">
                {props.videoAvailable ? 'video_library' : 'videocam_off'}
              </span>
              {props.videoAvailable ? 'YES' : 'NO'}
            </div>
          ),
          sortable: true,
        },
        {
          name: 'Featured',
          selector: (row) => JSON.stringify(row?.featured),
          sortable: true,
        },
        {
          name: 'Genre',
          selector: (row) => (row?.genre && Array.isArray(row?.genre) ? row?.genre.join(', ') : ''),
          sortable: true,
        },
        {
          name: 'DURATION',
          selector: (row) => row?.duration ?? '',
          sortable: true,
        },
        {
          name: 'RELEASED',
          selector: (row) =>
            row?.releaseyear
              ? row?.releaseyear
              : row?.releasedate
              ? moment(row?.releasedate).format('DD-MMM-YYYY')
              : '',
          sortable: true,
        },
        {
          name: 'STATUS',
          selector: (row) => (row?.status === 'INPROGRESS' ? 'IN PROGRESS' : row?.status),
          sortable: true,
        },
        ...(isChecked1
            ? [
                {
                  name: 'Select',
                  cell: (row) => (
                    
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="checkAll"
                      style={{ cursor: 'pointer' }}
                      onChange={(e) => handleCheckboxChange(row.contentid, e.target.checked)}
                      checked={selectedItems.includes(row.contentid)}
                    />
                  ),
                },
              ]
            : [
                {
                    name:
                      subValDashboard && subValDashboard.view && subValDashboard.view.display === true
                        ? 'Actions'
                        : null,
                    cell: (props) =>
                      subValDashboard && subValDashboard.view && subValDashboard.edit && (
                        <div className="d-flex">
                          {subValDashboard.view.display === true && (
                            <a
                              onClick={(e) => handleViewContent(e, props.contentid)}
                              className={`${
                                subValDashboard.view.enable === false ? 'pe-none' : ''
                              } text-success action-button`}
                            >
                              <i className="mdi mdi-eye font-size-18"></i>
                            </a>
                          )}
                          {subValDashboard.edit.display === true && (
                            <a
                              onClick={(e) => handleEditContent(e, props.contentid)}
                              className={`${
                                subValDashboard.edit.enable === false ? 'pe-none' : ''
                              } text-danger action-button`}
                            >
                              <i className="mdi mdi-pencil font-size-18"></i>
                            </a>
                          )}
                        </div>
                      ),
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                  },
            ]),
      
      ];
      
     //  console.log('currentPage norm',currentPageNew)
     //  console.log('rowsPerPage norm',rowsPerPage)
     const handlePageChange = (page) => {
         GetTimeActivity()
         setCurrentPage(page);
         // GetContent(page)
         GetContentNew(page)
 
     };
 
     const handlePerRowsChange = (newPerPage, page) => {
 
         GetTimeActivity()
         setRowsPerPage(newPerPage);
         setIsLoading(true);
         let payload = {}
         if (contentsearch) {
             payload = { 'title': contentsearch }
         } else {
             payload = searchPayload
         }
         const token = localStorage.getItem("token")
         const userid = localStorage.getItem("userId")
         const linkUrl = `${lambda}/content?appname=${appname}&token=${token}&userid=${userid}&pageNumber=${page}&assetcount=${newPerPage}`
         axios({
             method: 'POST',
             url: linkUrl,
             data: payload
         })
             .then(function (response) {
                 if (response?.data?.result == "Invalid token or Expired") {
                     setShowSessionPopupup(true)
                     setIsLoading(false);
                 } else {
                     const dataArray = Object.values(response?.data?.result?.data).map((obj) => obj);
                     setData(dataArray)
                     setContent(response?.data?.result)
                     setIsLoading(false);
 
                 }
             }).catch((err) => {
                 console.log('error', err);
                 setTableErrMsg(true)
                 setIsLoading(false);
             });
     };
     const handlePageChangeImport = (page) => {
         GetTimeActivity()
         setCurrentPageImport(page);
     };
     const handlePageChangeExport = (page) => {
         GetTimeActivity()
         setCurrentPageEport(page);
     }
     const handlePerRowsChangeExport = (newPerPage) => {
         GetTimeActivity()
         setRowsPerPageExport(newPerPage);
     }
     const handlePerRowsChangeImport = (newPerPage) => {
         GetTimeActivity()
         setRowsPerPageImport(newPerPage);
     };
 
     useEffect(() => {
 
         if (window.site === undefined) {
             setTimeout(() => {
                 if (window.site && window.site.common && window.site.common.imagesCDN) {
                     setImg(window.site.common.imagesCDN)
                 }
             }, 1000);
         }
         if (window.site && window.site.common && window.site.common.imagesCDN) {
             setImg(window.site.common.imagesCDN)
 
         }
 
     }, [window.site]);
     // console.log('contentsearch', contentsearch)
     // console.log('search payload', searchPayload)
 
 
     const handleShowPopup = () => {
         GetTimeActivity()
         setShowPopup(true);
     };
 
     const handleData = (childData, count) => {
         GetTimeActivity()
         console.log('handle data adv search', childData)
         setPay(childData);
     };
 
     const handleDownload = async (url, props) => {
         try {
             const response = await fetch(url);
             const blob = await response.blob();
             const blobUrl = window.URL.createObjectURL(blob);
             const fileName = props.downloadLink.split('/').pop();

             const link = document.createElement("a");
             link.href = blobUrl;
             link.setAttribute("download", fileName);
             document.body.appendChild(link);
             link.click();
             link.remove();
             window.URL.revokeObjectURL(blobUrl);
         } catch (error) {
             console.error("Download failed:", error);
         }
     };

 
 
     useEffect(() => {
         if (initialCategoriesData1.length > 0) {
             setData(initialCategoriesData1);
             setSearchedFlag(true);
         }
 
 
     }, [initialCategoriesData1]);
 
 
 
 
     const GetContentNew = async (page) => {
         setIsLoading(true);
         const token = localStorage.getItem("token");
         const userid = localStorage.getItem("userId");
         const linkUrl = `${lambda}/content?appname=${appname}&token=${token}&userid=${userid}&pageNumber=${page}&assetcount=${rowsPerPage}`;
         let payload = {};
 
         if (contentsearch) {
             // console.log("contentsearch", contentsearch);
             payload = { 'title': contentsearch };
         } else {
             payload = searchPayload;
         }
 
         if (sortingPayload) {
             payload = { ...payload, ...sortingPayload };
         }
 
         axios({
             method: 'POST',
             url: linkUrl,
             data: payload
         })
             .then(function (response) {
                 if (response?.data?.result == "Invalid token or Expired") {
                     setShowSessionPopupup(true);
                     setIsLoading(false);
                 } else {
                     const dataArray = Object.values(response?.data?.result?.data).map((obj) => obj);
                     setData(dataArray);
                     setContent(response?.data?.result);
                     setIsLoading(false);
                     setTotalRows(response?.data?.result?.totalContent);
                     setSearchedFlag(false);
                 }
             })
             .catch((err) => {
                 console.log('error', err);
                 setTableErrMsg(true)
                 setIsLoading(false);
             });
     };
     //  console.log('searchPayload',searchPayload)
     //  console.log('pay',pay)
     const handleSearchNew = async () => {
         setIsLoading(true);
 
         // console.log('currentPageNew', currentPageNew)
         const token = localStorage.getItem("token")
         const userid = localStorage.getItem("userId")
         const linkUrl = `${lambda}/content?appname=${appname}&token=${token}&userid=${userid}&pageNumber=${1}&assetcount=${rowsPerPage}`
         let payload = {}
         if (contentsearch) {
             console.log("contentsearch", contentsearch);
             payload = { 'title': contentsearch }
         } else {
             payload = searchPayload
             
         }
         setFlag(true)
         console.log('payload', payload)
         axios({
             method: 'POST',
             url: linkUrl,
             data: payload
         })
             .then(function (response) {
                 if (response?.data?.result == "Invalid token or Expired") {
                     setShowSessionPopupup(true)
                     setIsLoading(false);
                 } else {
                     const dataArray = Object.values(response?.data?.result?.data).map((obj) => obj);
                     if (dataArray.length > 0) {
                         setSearchExport(true);
                     } else {
                         setSearchExport(false); // Optionally, handle the case where the array is empty
                     }
                     setData(dataArray)
                     setContent(response?.data?.result)
                     setIsLoading(false);
                     setCurrentPage(1)
                     setTotalRows(response?.data?.result?.totalContent)
                     setMultiSelectFields({ dubbinglanguages: [], typeofrights: [], countryorigin: [], languages: [], typeofrights: [], genre: [], videoquality: [], certificate: [], subtitleslanguages: [], territoriesavailable: [], sport: [], musicgenre: [] })
                     setActiveFieldsObj({ CookingshowActive: false, seriesActive: false, SportsActive: false, LiveEventActive: false, MusicActive: false })
                     setSelectedOptionsClientName([])
                    //  setSearchPayload({})
                     setIsChecked1(false)
                     setPay({})
                     setSelectedItems([])
                     setSelectedOptions([])
                     setSearchedFlag(false);
 
                 }
             }).catch((err) => {
                 console.log('error', err);
                 setTableErrMsg(true)
                 setIsLoading(false);
             });
     };
     const GetContent = async (page) => {
         // console.log('get content function ')
         setIsLoading(true);
         const token = localStorage.getItem("token")
         const userid = localStorage.getItem("userId")
         const linkUrl = `${lambda}/content?appname=${appname}&token=${token}&userid=${userid}&pageNumber=${page}&assetcount=${rowsPerPage}`
 
         axios({
             method: 'POST',
             // url: lambda + '/content?appname=' + appname + "&token=" + token + "&userid=" + userid,
             url: linkUrl,
 
         })
             .then(function (response) {
                 if (response?.data?.result == "Invalid token or Expired") {
                     setShowSessionPopupup(true)
                     setIsLoading(false);
                 } else {
                     // setData(response?.data?.result?.data)
                     const dataArray = Object.values(response?.data?.result?.data).map((obj) => obj);
                     setData(dataArray)
                     setContent(response?.data?.result)
                     setIsLoading(false);
                     setTotalRows(response?.data?.result?.totalContent)
 
                     setSearchedFlag(false);
 
                 }
             }).catch((err) => {
                 console.log('error', err);
                 setTableErrMsg(true)
                 setIsLoading(false);
             });
     };
 
 
     const handleCompanyInclude = (e) => {
         // console.log('e.target.checked',e.target.checked)
         // console.log('e.target.name',e.target.name)
         if (e.target.checked === true) {
             setIsCompanyInclude(true)
         } else {
             setIsCompanyInclude(false)
         }
     }
 
 
 
     const handleSearch = (e, flagggg) => {
 
         if (flagggg === "normalsearch" || pay.length > 0) {
             console.log('handle search flag', searchedFlag)
             setSearchedFlag(true)
         }
         if (flagggg) {
             setCurrentPage(1);
             setRowsPerPage(15);
         }
         setFlag(true)
 
         if (contentsearch === "" && pay.length <= 0) {
             console.log("called get content");
             GetContent(1);
 
 
         }
 
         else {
             let payload
             setIsLoading(true);
             setCurrentPage(1);
             console.log("starting");
             if (contentsearch) {
                 console.log("title");
                 payload = { 'title': contentsearch }
             } else {
                 console.log("payloadddd");
                 payload = searchPayload
             }
             const token = localStorage.getItem("token")
             const userid = localStorage.getItem("userId")
             const linkUrl = `${lambda}/content?appname=${appname}&token=${token}&userid=${userid}&pageNumber=${1}&assetcount=${rowsPerPage}`
 
             axios({
                 method: 'POST',
                 // url: lambda + '/content?appname=' + appname + "&token=" + token + "&userid=" + userid,
                 url: linkUrl,
                 data: payload,
             })
                 .then(function (response) {
                     if (response?.data?.result) {
                         if (response?.data?.result?.data && response?.data?.result?.data.length == 0) {
                             setIsLoading(false)
                         } else if (response?.data?.result == "Invalid token or Expired") {
                             setShowSessionPopupup(true)
                         } else {
                             setSuccess(false);
                         }
                         console.log("response", response?.data?.result);
                         setData(response?.data?.result?.data);
                         setContent(response?.data?.result);
                         setTotalRows(response?.data?.result?.totalContent)
                         setIsLoading(false);
 
                     }
                 }).catch((err) => {
                     console.log('error', err);
                     setTableErrMsg(true)
                     setIsLoading(false);
                 });
         }
     }
     const clearSearch = () => {
         setSuccess(false);
         // GetContent(1);
         setIsChecked1(false)
 
         setPay({});
         setSelectedOptions([])
         setMultiSelectFields({ dubbinglanguages: [], typeofrights: [], countryorigin: [], languages: [], typeofrights: [], genre: [], videoquality: [], certificate: [], subtitleslanguages: [], territoriesavailable: [], sport: [], musicgenre: [] })
         setActiveFieldsObj({ CookingshowActive: false, seriesActive: false, SportsActive: false, LiveEventActive: false, MusicActive: false })
         setSelectedOptionsClientName([])
         setSearchPayload({})
         setSortingPayload("")
         setContentSearch("")
         setSelectAll(false)
         setSearchExport(false)
         setCurrentPage(1);
         //  GetContentNew(1)
         GetContent(1)
     }
 
     const handleChange = (e) => {
 
         if ((e.key === "Enter")) {
             setTimeout(function () {
                 handleSearchNew();
             }, 100);
         }
         setContentSearch(e.target.value)
     }
 
 
 
     const handleKeypress = (e) => {
 
         if ((e.key === "Enter")) {
             setTimeout(function () {
                 handleSearchNew();
             }, 100);
         }
     };
     const customNoRecords = () => {
         return (
 
             <div className="empty-state-body empty-record"  >
                 <div className="empty-state__message">
                     <span className="material-icons">summarize</span>
                     <p className="form-check font-size-16">No content was found for the searched keyword</p>
                 </div> </div>
         )
     }
     function onConfirm() {
         setSuccess(false);
         setIsAlert(false)
         if (exportData.length <= 0) {
             setExportFlag((prevState) => !prevState);
         }
 
     };
     const handleUpload = () => {
         history.push("/contentupload");
     };
     const handleImportContent = () => {
         // setActiveExportBtn("")
         setImportFlag((prevState) => !prevState);
 
         if (exportFlag === true) {
             setExportData({})
         }
         if (importFlag === false) {
             if (exportFlag === false) {
                 setExportFlag(true)
             }
             fetchImportData();
 
         }
         if (importFlag === true) {
             setImportData({})
         }
 
     };
     const handleAddContent = () => {
         history.push("/addcontent");
     }
 
     //  console.log('import flag',importFlag)
     //  console.log('export flag',exportFlag)
     const handletoggle = async() => {
         console.log('handle toggle')
         setActiveExportBtn("")
        
         setExportFlag((prevState) => !prevState);
 
         if (exportFlag === true) {
             fetchExportData();
 
         }
         if (exportFlag === false) {
             setIsCompanyInclude(false)
             setExportData({})
         }
         console.log('import flag inside', importFlag)
         console.log('export flag inside', exportFlag)
     };
     const handleSelectCheckboxChange = (event) => {
        setIsChecked1(event.target.checked);
        setIsLoading(true);
        if(event.target.checked){
            setExportMsg(true)
        }
        
        // console.log('currentPageNew', currentPageNew)
        const token = localStorage.getItem("token")
        const userid = localStorage.getItem("userId")
        const assetCount = event.target.checked ? totalRows : 15;
const linkUrl = `${lambda}/content?appname=${appname}&token=${token}&userid=${userid}&pageNumber=1&assetcount=${assetCount}`;
        let payload = {}
        
        if (contentsearch) {
            console.log("contentsearch", contentsearch);
            payload = { 'title': contentsearch }
            
         
        }else if(pay){
            payload = { ...payload, ...pay }
           
          
        } else {
            payload = searchPayload
            

        }
        setFlag(true)
        console.log('payload', payload)
        axios({
            method: 'POST',
            url: linkUrl,
            data: payload
        })
            .then(function (response) {
                if (response?.data?.result == "Invalid token or Expired") {
                    setShowSessionPopupup(true)
                    setIsLoading(false);
                } else {
                    const dataArray = Object.values(response?.data?.result?.data).map((obj) => obj);
                    if (dataArray.length > 0) {
                        setSearchExport(true);
                    } else {
                        setSearchExport(false); // Optionally, handle the case where the array is empty
                    }
                    setData(dataArray)
                    setContent(response?.data?.result)
                    setIsLoading(false);
                    setSelectAll(false)
                    setCurrentPage(1)
                    // setTotalRows(response?.data?.result?.totalContent)
                    // setMultiSelectFields({ dubbinglanguages: [], typeofrights: [], countryorigin: [], languages: [], typeofrights: [], genre: [], videoquality: [], certificate: [], subtitleslanguages: [], territoriesavailable: [], sport: [], musicgenre: [] })
                    // setActiveFieldsObj({ CookingshowActive: false, seriesActive: false, SportsActive: false, LiveEventActive: false, MusicActive: false })
                    // setSelectedOptionsClientName([])
                    // setSearchPayload({})
                    // setPay({})
                    // setSelectedOptions([])
                    setSearchedFlag(false);

                }
            }).catch((err) => {
                console.log('error', err);
                setTableErrMsg(true)
                setIsLoading(false);
            });
        // console.log(event.target.checked ? 'true' : 'false');
      };

     const fetchImportData = (e, id) => {
         setImportLoad(true)
         const importid = id || '';
         const token = localStorage.getItem("token");
         const userid = localStorage.getItem("userId");
         const importidParam = importid ? `&importId=${importid}` : '';
 
         const linkUrl = `${lambda}/importReports?appname=${appname}&token=${token}&userid=${userid}${importidParam}`;
 
         axios({
             method: 'POST',
             url: linkUrl
         })
             .then(function (response) {
                 if (response) {
                     if (response?.data?.result == "Invalid token or Expired") {
                         setShowSessionPopupup(true)
                     } else {
                         if (importid) {
                             let rejectedList = response?.data?.result?.[0]?.result?.data?.[0]?.rejected
                             console.log('rejected list', rejectedList)
                             handlecheck(rejectedList, 'data')
                         } else {
                             setImportData(response?.data?.result);
                             // console.log("export data", response?.data?.result);
                         }
                     }
                     setImportLoad(false)
                     setActiveImportCheckId("")
                 }
                 setImportLoad(false)
                 setActiveImportCheckId("")
 
             }).catch(err => {
                 setActiveImportCheckId("")
                 console.log('err', err)
             });
     };
     //  console.log('aasdfadsfasdf')
     const fetchExportData = (e) => {
         setExportLoad(true)
 
         const token = localStorage.getItem("token");
         let userid = localStorage.getItem("userId");
         axios({
             method: 'POST',
             url: lambda + '/fetchExports?appname=' + appname + "&source=content" + "&token=" + token + "&userid=" + userid,
         })
             .then(function (response) {
                 if (response) {
                     if (response?.data?.result == "Invalid token or Expired") {
                         setShowSessionPopupup(true)
                     } else {
                         setExportData(response?.data?.result);
                         //  console.log("export data", response?.data?.result);
 
 
                     }
                     setExportLoad(false)
                 }
                 setExportLoad(false)
 
             });
     };
     const handleImportRefresh = (e, id) => {
         console.log('handle import refresh', id)
         setActiveImportCheckId(id)
         fetchImportData()
     }
     const handleRefresh = (e, item) => {
         setActiveCheckId(item.exportId)
         const token = localStorage.getItem("token")
         let userid = localStorage.getItem("userId");
         let exportId = item.exportId
         axios({
             method: 'POST',
             url: lambda + '/fetchExports?appname=' + appname + "&userid=" + userid + "&source=content" + "&token=" + token + "&exportid=" + exportId
         })
             .then(function (response) {
                 if (response) {
                     if (response?.data?.result == "Invalid token or Expired") {
                         setShowSessionPopupup(true)
                     } else {
                         console.log('response----------------->', response)
 
 
                         const updatedRecord = response?.data?.result && response?.data?.result?.[0];
 
                         const updatedData1 = exportData.map((record) => {
                             if (record.exportId === exportId) {
                                 return { ...record, ...updatedRecord };
                             }
                             return record;
                         });
 
                         console.log('updatedData', updatedData1)
 
                         setExportData(updatedData1);
                     }
                     setActiveCheckId("")
                 }
                 setActiveCheckId("")
 
             });
     };
 
     const handleDeleteExportFilePopup = (e, fileid) => {
         setDelete(true);
         setItem(fileid);
     }
 
     function onCancel() {
         setDelete(false)
     }
 
 
     const handleDeleteExportFile = (e) => {
         let userid = localStorage.getItem("userId");
         const token = localStorage.getItem("token")
         const id = item
         setLoaderDelete(true)
         axios({
             method: 'DELETE',
             url: lambda + '/deleteExports?appname=' + appname + "&exportid=" + id + "&userid=" + userid + "&token=" + token,
         })
             .then(function (response) {
                 if (response) {
                     if (response?.result == "Invalid token or Expired") {
                         setShowSessionPopupup(true)
                     } else {
 
                         fetchExportData();
                         setSuccess(true)
                     }
                     setLoaderDelete(false)
                     setDelete(false)
 
                 }
             });
     }
 
 
 
     useEffect(() => {
         if (exportFlag === false && exportData && exportData.length > 0) {
 
 
             const interval = setInterval(() => {
                 fetchExportData();
 
             }, 10000);
             return () => clearInterval(interval);
         }
     }, [exportFlag, exportData]);
 
     const handlecheck = (data, fileName) => {
 
         console.log('data before', data)
         for (let key in data) {
             delete data[key]['_id']
             delete data[key]['created']
             delete data[key]['companyid']
             delete data[key]['status']
             delete data[key]['mediaType']
             delete data[key]['appname']
             delete data[key]['source']
             delete data[key]['importId']
 
 
             for (let item in data[key]) {
                 console.log("iteeeeee", typeof (data[key][item]));
                 if (typeof (data[key][item]) === "object") {
                     data[key][item] = data[key] && data[key][item] && data[key][item].length > 1 && data[key][item]?.join(', ');
                 } else {
                     data[key][item] = data[key][item];
                 }
 
             }
 
 
         }
         console.log('data after', data)
 
         exportToExcel(data, "data");
 
     }
     function exportToExcel(jsonData, fileName) {
         delete jsonData['_id'];
         const worksheet = XLSX.utils.json_to_sheet(jsonData);
         const workbook = XLSX.utils.book_new();
         XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
         const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
         const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
         //FileSaver.saveAs(data, `${fileName}.xlsx`);
         FileSaver.saveAs(data, `rejectedcontent.xlsx`);
     }
     useEffect(() => {
         if (importFlag === true && importData.length > 0) {
 
 
             const interval = setInterval(() => {
                 fetchImportData();
 
             }, 10000);
             return () => clearInterval(interval);
         }
     }, [importFlag, importData]);
     useEffect(() => {
         if (importFlag === true) {
             fetchImportData();
         }
     }, [importFlag]);
     
 
     const handleExport = (e, type) => {
         setActiveExportBtn(type)
         let userid = localStorage.getItem("userId");
         let obj = {};
 
         if (contentsearch) {
             obj = { 
                ...(isChecked1 ? { contentid: selectedItems } : {"title": contentsearch})
              }
         }  else if (pay) {
             obj = {  ...(isChecked1 ? { contentid: selectedItems } : {...obj, ...pay }) }
            
         }
         if (isCompanyInclude) {
             obj = { ...obj, "companyname": true }
         }
 
         const token = localStorage.getItem("token")
 
         axios({
             method: 'POST',
             url: lambda + '/export?appname=' + appname + "&userid=" + userid + '&type=' + type + "&source=content" + "&token=" + token,
             data: obj
         })
             .then(function (response) {
                 if (response?.data?.result == "No data found with the filterd values") {
                     setIsAlert(true)
                 }
                 else {
                     setBtnDisable(true)
                     fetchExportData();
                 }
             });
         // }
     }
 
//  console.log("selectedItemssssssssssssssssssssssssss",selectedItems)
 
     return (
         <>
             {showSessionPopupup && <SessionPopup />}
             <div id="layout-wrapper">
                 <Header />
                 <Sidebar />
 
 
 
                 <div className={`main-content user-management content-management ${exportFlag === false ? 'export' : ""} ${importFlag === true ? 'import_content' : ''}`}>
 
                     <div className="page-content">
                         <div className="container-fluid">
 
 
 
                             <div className="row mb-4 breadcrumb">
                                 <div className="col-lg-12">
                                     <div className="d-flex align-items-center">
                                         <div className="flex-grow-1">
                                             <div className="title-block">
                                                 <h4 className="mb-2 card-title">{importFlag === false ? 'content management' : 'Import Content'}</h4>
                                                 <div className="dropdown d-flex">
 
                                                     {subValDashboard && subValDashboard.add && subValDashboard.add.display === true && importFlag === false &&
                                                         <button className={`${subValDashboard && subValDashboard.add && subValDashboard.add.enable === false ?
                                                             'disable' : ''
                                                             } btn-primary me-2`} type="button" disabled={subValDashboard && subValDashboard.add && subValDashboard.add.enable === false} onClick={handleAddContent}>
                                                             ADD CONTENT
 
                                                         </button>}
                                                     {subValDashboard && subValDashboard.import && subValDashboard.import.display === true &&
                                                         <button className={`${subValDashboard && subValDashboard.import && subValDashboard.import.enable === false ?
                                                             'disable' : ''
                                                             } btn-primary`} type="button"
                                                             disabled={subValDashboard && subValDashboard.import && subValDashboard.import.enable === false}
                                                             // onClick={handleUpload}
                                                             onClick={importFlag === false ? handleImportContent : handleUpload}
                                                         >
                                                             {importFlag === false ? 'IMPORT' : 'CONTENT UPLOAD'}
 
                                                         </button>
                                                     }
 
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                             </div>
 
                             <div className="row table-data " >
                                 <div className="col-12">
                                     <div className="card">
                                         <div className={isChecked1?"card-body select-export":"card-body"}>
                                             <div className="row mb-2">
                                                 {!importFlag ?
                                                     <div className={`${exportFlag === true ? 'col-sm-4' : 'col-sm-6'} button-block`} style={{ width: exportFlag === true && "40%" }} >
                                                         {subValDashboard && subValDashboard.export && subValDashboard.export.display === true && <>
                                                             {exportFlag === true ?
                                                                 <div className="dataTables_length d-flex gap-2" id="datatable_length">
                                                                    {searchExport && totalRows<=100 && 
                                                                    <div className="export_title_block"style={{ border: 'none', padding: '0' }}>
                                                                         <div className="export_title" style={{ border: 'none'}}>
                                                                  <div className="font-size-16">
                                                               <input className="form-check-input" type="checkbox" disabled={validateObj && validateObj[0] && subValDashboard && subValDashboard.export && subValDashboard.export.enable === false} checked={isChecked1} onChange={handleSelectCheckboxChange}/>
                                                              </div>
                                                                <p>Select and Export</p>
                                                                  </div>
                                                                 
                                                                  {isChecked1 && 
                                                                  < div className="export_title ms-2" style={{ border: 'none'}}>
                                                                  <div className="font-size-16">
                                                                 
                                                               <input className="form-check-input" type="checkbox" disabled={validateObj && validateObj[0] && subValDashboard && subValDashboard.export && subValDashboard.export.enable === false}  checked={selectAll}
                    onChange={(e) => handleSelectAllChange(e.target.checked)}/>
                                                              </div>
                                                              <p>Select All</p>
                                                                  </div>}
                                                                         </div>}
                                                                 
                                                                     <button className={`${subValDashboard && subValDashboard.export && subValDashboard.export.enable === false ?
                                                                         'disable pe-none' : ''
                                                                         } fill_btn`} disabled={validateObj && validateObj[0] && subValDashboard && subValDashboard.export && subValDashboard.export.enable === false||isChecked1&&selectedItems?.length<=0} onClick={handletoggle}><span className="material-icons">north_east</span>Export</button>
                                                                        
                                                    
                                                                     {/* {searchExport && totalRows<=200 && (
                                                                         <button className="fill_btn" onClick={handleSelect}>
                                                                             <span className="material-icons">north_east</span>Select and Export
                                                                         </button>
                                                                     )} */}
 
 
                                                                 </div>
                                                                 :
                                                                 <>
                                                                     <div className="dataTables_length" id="datatable_length">
                                                                         <button className="fill_btn" onClick={handletoggle}><span className="material-icons">arrow_back</span>BACK</button>
                                                                     </div>
                                                                     {data.length > 0 ? <>
                                                                         <div className="dataTables_length" id="datatable_length">
                                                                             <button
                                                                                 // className={`fill_btn text-danger border-danger fw-bolder ${btnDisable ? 'disable pe-none':''}`}
                                                                                 className={`fill_btn text-danger border-danger fw-bolder ${activeExportBtn == 'pdf' ? 'active' : ''}`}
 
                                                                                 onClick={(e) => handleExport(e, "pdf")}>PDF</button>
                                                                         </div><div className="dataTables_length" id="datatable_length">
                                                                             <button
 
 
                                                                                 className={`fill_btn text-success border-success fw-bolder ${activeExportBtn == 'csv' ? 'active' : ''}`}
 
                                                                                 onClick={(e) => handleExport(e, "csv")}>CSV</button>
                                                                         </div><div className="dataTables_length" id="datatable_length">
                                                                             <button
 
                                                                                 className={`fill_btn text-primary border-primary fw-bolder ${activeExportBtn == 'xls' ? 'active' : ''}`}
 
                                                                                 onClick={(e) => handleExport(e, "xls")}>XLS</button>
                                                                         </div>
                                                                         <div className="dataTables_length" id="datatable_length">
                                                                             <button
 
 
                                                                                 className={`fill_btn text-warning border-warning fw-bolder ${activeExportBtn == 'fullxls' ? 'active' : ''}`}
 
 
                                                                                 onClick={(e) => handleExport(e, "fullxls")}>FULL XLS</button>
                                                                         </div>
 
 
 
                                                                     </>
                                                                         : null}
 
                                                                 </>
                                                             }
                                                         </>
                                                         }
 
 
 
 
 
                                                     </div> : <div className="dataTables_length" id="datatable_length">
                                                         <button className="fill_btn" onClick={handleImportContent}><span className="material-icons">arrow_back</span>BACK</button>
                                                     </div>
 
 
                                                 }
 
                                                 {(exportFlag === true && importFlag === false) ?
                                                     <div className="col-sm-8" style={{width:"60%"}}>
                                                         <div className="search-box mb-2 d-inline-block">
                                                             <div className="position-relative">
                                                                 <input type="text" className="form-control" value={contentsearch} onChange={(e) => handleChange(e)} onKeyPress={handleKeypress} placeholder="Search Title" />
                                                                 <button className="fill_btn" onClick={(e) => handleSearchNew(1)}><span className="material-icons search-icon">search</span></button>
                                                             </div>
                                                             <div className="dataTables_length" id="datatable_length">
                                                                 <button className="fill_btn" onClick={handleShowPopup}>Advanced Search</button>
                                                             </div>
                                                             <div className="dataTables_length" id="datatable_length">
                                                                 <button className="fill_btn" onClick={clearSearch}><span className="material-icons-outlined">sync</span>Reset</button>
                                                             </div>
 
                                                         </div>
                                                         <div className="text-sm-end">
 
                                                         </div>
                                                     </div> : importFlag === false && <div className="col-sm-6">
                                                         <div className="input-field switch-buttons d-flex"><label className="switch"><input type="checkbox" name="companyname" onChange={(e) => handleCompanyInclude(e)} /><span className="slider round"></span></label><label className="col-form-label">Company Name</label></div>
                                                     </div>}
                                             </div>
 
                                             {exportFlag === false ?
 
 
                                                 exportData && exportData.length > 0 ?
                                                     <>
 
                                                         < DataTable
                                                             // title=""
                                                             columns={columnsExport}
                                                             // className="table align-middle table-nowrap table-check"
                                                             keyField='_id'
                                                             data={exportData}
                                                             direction="auto"
                                                             highlightOnHover
                                                             fixedHeaderScrollHeight="300px"
                                                             pagination
                                                             responsive
                                                             persistTableHead
                                                             // selectableRowsVisibleOnly
                                                             striped
                                                             // selectableRowsHighlight
                                                             // selectableRows
                                                             subHeaderAlign="right"
                                                             defaultSortField="name"
                                                             subHeaderWra
                                                             noDataComponent={customNoRecords()}
                                                             paginationTotalRows={exportData.length}
                                                             onChangeRowsPerPage={handlePerRowsChangeExport}
                                                             onChangePage={handlePageChangeExport}
                                                             paginationPerPage={rowsPerPageExport}
                                                             paginationDefaultPage={currentPageExport}
                                                             paginationRowsPerPageOptions={[15, 25, 50, 75, 100]}
 
                                                             paginationComponentOptions={{
                                                                 rowsPerPageText: 'Per page:',
                                                                 rangeSeparatorText: 'of',
                                                                 noRowsPerPage: false,
                                                                 selectAllRowsItem: false,
                                                                 selectAllRowsItemText: 'All',
                                                             }}
 
                                                         //    progressPending={importLoad}
                                                         //    progressComponent={<TableLoader />}
                                                         />
 
                                                     </>
                                                     :
                                                     exportLoad ?
                                                         <div className="empty-state-body export-empty" >
                                                             <div className="empty-state__message">
                                                                 <TableLoader />
                                                             </div> </div>
 
                                                         :
                                                         <div className="empty-state-body export-empty" >
                                                             <div className="empty-state__message">
                                                                 <span className="material-icons">summarize</span>
                                                                 <p className="form-check font-size-16">No Export Data was found</p>
                                                             </div> </div> :
                                                 importFlag === true ?
                                                     importData && importData.length > 0 ?
                                                         <>
                                                             {/* <div className="table-responsive">
                                                                   <table className="table align-middle table-nowrap table-check export-table-data" id="table">
                                                                       <thead className="table-light">
                                                                           <tr>
                                                                               <th></th>
                                                                               <th className="align-middle">Import Id</th>
                                                                               <th className="align-middle">User Name</th>
                                                                               <th className="align-middle">Start At</th>
                                                                               <th className="align-middle">End At</th>
                                                                               <th className="align-middle">Status</th>
                                                                               <th className="align-middle">Added</th>
                                                                               <th className="align-middle">Updated</th>
                                                                               <th className="align-middle">Rejected</th>
   
                                                                               <th className="align-middle">Actions</th>
                                                                           </tr>
                                                                       </thead>
                                                                 
                                                                       <tbody>
                                                                           {importData && importData.length > 0 && importData.map(function (item, i) {
                                                                              
                                                                               return (
                                                                                   <tr key={i}>
                                                                                       <th></th>
                                                                                       <td>{item?.importId}</td>
                                                                                       <td>{item?.importedBy?.username}</td>
   
                                                                                       <td>
                                                                                           {new Date(item?.created).toLocaleDateString('en-IN', {
                                                                                               day: 'numeric',
                                                                                               month: 'short',
                                                                                               year: 'numeric',
                                                                                               hour: 'numeric',
                                                                                               minute: 'numeric',
                                                                                           })}
                                                                                       </td>
                                                                                       <td>
                                                                                           {new Date(item?.completedOn).toLocaleDateString('en-IN', {
                                                                                               day: 'numeric',
                                                                                               month: 'short',
                                                                                               year: 'numeric',
                                                                                               hour: 'numeric',
                                                                                               minute: 'numeric',
                                                                                           })}
                                                                                       </td>
                                                                                       <td>{item?.status === "INPROGRRESS" ? <><label>In Progress</label><div className="progress">
                                                                                           <div className="progress-bar progress-bar-striped bg-info" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                                                       </div></> : item?.status}
                                                                                       </td>
                                                                                       <td>{item?.result?.data?.[0]?.insertedCount}</td>
                                                                                       <td>{item?.result?.data?.[0]?.updatedCount}</td>
                                                                                       <td>{item?.result?.data?.[0]?.rejectedCount}</td>
   
                                                                                       <td>
                                                                                           <div className="d-flex">
                                                                                               {item.status === "Inprogress" ? <a className="text-success action-button" onClick={(e) => handleImportRefresh(e, item.importId)}>
                                                                                                   <span className="material-icons-outlined text-warning">
                                                                                                       {activeImportCheckId === item.importId ?
                                                                                                           (<img src="https://d9nwtjplhevo0.cloudfront.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg"
                                                                                                               className="loading-icon" />) :
   
   
                                                                                                           <span className="material-icons-outlined">refresh</span>}
                                                                                                   </span></a>
                                                                                                   : item?.result?.data?.[0]?.rejectedCount > 0 ? <a className="text-success action-button"
                                                                                                      
                                                                                                       onClick={(e) => fetchImportData(e, item?.importId)}
                                                                                                   ><span className="material-icons-outlined text-success">download</span></a> : null}
   
   
                                                                                           </div>
                                                                                       </td>
                                                                                   </tr>
                                                                               )
                                                                           })}
   
                                                                       </tbody>
   
   
                                                                   </table>
                                                               </div> */}
                                                             < DataTable
                                                                 // title=""
                                                                 columns={columnsImport}
                                                                 // className="table align-middle table-nowrap table-check"
                                                                 keyField='_id'
                                                                 data={importData}
                                                                 direction="auto"
                                                                 highlightOnHover
                                                                 fixedHeaderScrollHeight="300px"
                                                                 pagination
                                                                 responsive
                                                                 persistTableHead
                                                                 // selectableRowsVisibleOnly
                                                                 striped
                                                                 // selectableRowsHighlight
                                                                 // selectableRows
                                                                 subHeaderAlign="right"
                                                                 defaultSortField="name"
                                                                 subHeaderWra
                                                                 noDataComponent={customNoRecords()}
                                                                 paginationTotalRows={importData.length}
                                                                 onChangeRowsPerPage={handlePerRowsChangeImport}
                                                                 onChangePage={handlePageChangeImport}
                                                                 paginationPerPage={rowsPerPageImport}
                                                                 paginationDefaultPage={currentPageImport}
                                                                 paginationRowsPerPageOptions={[15, 25, 50, 75, 100]}
 
                                                                 paginationComponentOptions={{
                                                                     rowsPerPageText: 'Per page:',
                                                                     rangeSeparatorText: 'of',
                                                                     noRowsPerPage: false,
                                                                     selectAllRowsItem: false,
                                                                     selectAllRowsItemText: 'All',
                                                                 }}
 
                                                             //    progressPending={importLoad}
                                                             //    progressComponent={<TableLoader />}
                                                             />
 
                                                         </>
 
 
                                                         : importLoad ? <div className="empty-state-body export-empty" >
                                                             <div className="empty-state__message">
                                                                 <TableLoader />
                                                             </div> </div> : <div className="empty-state-body export-empty" >
                                                             <div className="empty-state__message">
                                                                 <span className="material-icons">summarize</span>
                                                                 <p className="form-check font-size-16">No Import Data was found</p>
                                                             </div> </div> :
                                                     <>
                                                         < DataTable key={keyForRerender}
                                                             // title=""
                                                             columns={columns}
                                                             // className="table align-middle table-nowrap table-check"
                                                             keyField='_id'
                                                             data={data}
                                                             direction="auto"
                                                             highlightOnHover
                                                             fixedHeaderScrollHeight="300px"
                                                             {...(isChecked1 ? {} : { pagination: true })}
                                                             paginationServer
                                                             responsive
                                                             persistTableHead
                                                             // selectableRowsVisibleOnly
                                                             striped
                                                             // selectableRowsHighlight
                                                             // selectableRows
                                                             subHeaderAlign="right"
                                                             defaultSortField="name"
                                                             subHeaderWrap
                                                             noDataComponent={customNoRecords()}
                                                             paginationTotalRows={totalRows}
                                                             onChangeRowsPerPage={handlePerRowsChange}
                                                             onChangePage={handlePageChange}
                                                             paginationPerPage={rowsPerPage}
                                                             paginationDefaultPage={currentPageNew}
                                                             paginationRowsPerPageOptions={[15, 25, 50, 75, 100]}
 
                                                             paginationComponentOptions={{
                                                                 rowsPerPageText: 'Per page:',
                                                                 rangeSeparatorText: 'of',
                                                                 noRowsPerPage: false,
                                                                 selectAllRowsItem: false,
                                                                 selectAllRowsItemText: 'All',
                                                             }}
                                                             onSort={handleSort}
                                                             // sortFunction={customSort}
                                                             progressPending={isLoading}
                                                             progressComponent={<TableLoader />}
                                                         />
 
 
                                                     </>
                                             }
 
                                         </div>
                                     </div>
                                 </div>
                             </div>
 
 
 
                         </div>
 
                     </div>
                     <SweetAlert show={success}
                         custom
                         confirmBtnText="ok"
                         confirmBtnBsStyle="primary"
                         title={"Deleted SuccessFully"}
                         onConfirm={e => onConfirm()}
                     >
                     </SweetAlert>
                     <SweetAlert show={exportMsg}
                         custom
                         confirmBtnText="ok"
                         confirmBtnBsStyle="primary"
                         title={"Choose titles using select column and press \'Export Button\'"}
                         onConfirm={e => setExportMsg(false)}
                     >
                     </SweetAlert>
                     <SweetAlert show={isAlert}
                         custom
                         confirmBtnText="ok"
                         confirmBtnBsStyle="primary"
                         title={"No content to export as all selected are inactive content"}
                         onConfirm={e => onConfirm()}
                     >
                     </SweetAlert>
                     <SweetAlert show={tableErrMsg}
                         custom
                         confirmBtnText="ok"
                         confirmBtnBsStyle="primary"
                         title={"Something went wrong. Please reload again."}
                         onConfirm={e => setTableErrMsg(false)}
                     >
                     </SweetAlert>
                     <Modal className="advance-search" show={popup} onHide={handleClosePopup} backdrop="static">
                         <Modal.Header closeButton>
                             <Modal.Title>Advanced Search</Modal.Title>
                         </Modal.Header>
                         <Modal.Body><AdvanceSearch onData={handleData} /></Modal.Body>
 
                     </Modal>
                     <Modal className="access-denied" show={isdelete}>
 
                         <div className="modal-body enquiry-form">
                             <div className="container">
                                 <button className="close-btn" onClick={e => onCancel()}><span className="material-icons">close</span></button>
                                 <span className="material-icons access-denied-icon">delete_outline</span>
                                 <h3>Delete</h3>
                                 <p>This action cannot be undone.</p>
                                 <p>Are you sure you want to delete File?</p>
                                 <div className="popup-footer">
                                     <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => handleDeleteExportFile()}> {loaderDelete ? (<img src="https://d9nwtjplhevo0.cloudfront.net/orasi/client/resources/orasiv1/images/common-icons/rotate_right.svg" className="loading-icon" />) : null}Yes, Delete</button>
                                 </div>
                             </div>
                         </div>
 
                     </Modal>
                     <Footer />
                 </div>
 
 
 
             </div>
         </>
     );
 };
 
 export default ContentMange;